import moment from'moment';
import { useCallback, useEffect, useState } from 'react';

import styles from './index.module.scss';
import { createSdFile, listSdFiles } from '@/services/SdFile';
import { SdFile } from '@/models/sd/SdFile';
import { Avatar, Button, Empty, Form, Select, Spin } from 'antd';
import { GEN_TYPES } from '@/constants';
import { newPage } from '@/utils';
import { useParams } from 'react-router-dom';
import { getTrainResult, startTraining } from '@/services/DigitalTwin';
import ImageUploader from '@/components/DigitalImageUploader/ImageUploader';

moment.locale('zh-cn');

const me = INIT_STATE.me;

function DigitalTwinDetail() {
  const {publicId} = useParams<string>();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [imageFileIds, setImageFileIds] = useState([]);

  useEffect(() => {
    async function fetchTrainResult() {

      // const resp = await getTrainResult(publicId);


    }

    fetchTrainResult();
  }, [publicId]);

  const handleStartTraining = useCallback(async () => {
    if (!publicId) return;
    const resp = await startTraining(publicId, imageFileIds)
    console.log('startTraining result: ', resp);

  }, [imageFileIds, publicId]);

  return (
    <div className={styles.container}>
      <div className={styles.actionSection}>
        <div className={styles.selectionSection}>
        </div>
        <div>
          {publicId}
          <Button onClick={handleStartTraining} type='primary'>开始训练</Button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentSection}>
          <ImageUploader 
            onUploaded={(data) => {
              console.log('onuploaded: ', data);
              setImageFileIds(data?.fileList.map(item => item.url))
            }}
            multiple
          />
          <div className={styles.cardList}>
            {
              imageFileIds.map(item => {
                return <ImageCard item={item} />
              })
            }
          </div>
          <Spin style={{ minHeight: 300}} spinning={globalLoading}> 
          
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default DigitalTwinDetail;
