import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Dropdown, FloatButton, Layout, Modal, Popover, Select, Space } from 'antd';
import { BookOutlined, CommentOutlined, CustomerServiceOutlined, GithubOutlined, GlobalOutlined, MailOutlined, MenuOutlined, PayCircleOutlined, QrcodeOutlined, RiseOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';
import { useGlobalStore } from '@/store'

import DashboardMenu from './menu';
import styles from './index.module.scss';
import GroupQrCode from '@/components/Feedback/GroupQRCode';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import React from 'react';
import ReactDOM from 'react-dom';

const { Header, Content } = Layout;

export const TransparentHeader = ({
  unselected,
  setUnselected,
  onMenuSelect,
  className,
  theme = 'dark'
}) => {

  return <div className={`${styles.transparentHeader} bg-black/30 backdrop-blur-[20px] border-b border-white/[0.05] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.1)] ${className}`}>
    <div>
      <div className={styles.logo} />
    </div>
    <div className={styles.right}>
      <div className={styles.hozMenu} ><DashboardMenu theme={theme} unselected={unselected} mode="horizontal" onSelect={onMenuSelect} /></div>
      <NavActions onEnterUserCenter={setUnselected?.bind(null, true)} theme={theme} />
      <Dropdown menu={{items: [
        {
          label: (
            <DashboardMenu onSelect={onMenuSelect} unselected={unselected} mode="vertical"/>
          ),
          key: '0',
        },
      ]}}>
        <div className={`${styles.verMenu} ${theme === 'light' ? styles.verMenuLight : ''}`}><Button type="text"><MenuOutlined style={{ fontSize: 20 }} /></Button></div>
      </Dropdown>
    </div>
  </div>;
}

export const NavActions = (props) => {
  const { onEnterUserCenter, className, theme = 'dark' } = props;
  const locale = useGlobalStore((state) => state.locale);
  const user = useGlobalStore((state) => state.currentUser);
  const logout = useGlobalStore((state) => state.logout);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const negative = useNavigate();
  const [ t, i18n ] = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  const handleSignUp = () => {
    showLoginModal();
  }

  const handleLogout = async () => {
    await logout();
  }

  const entryUserCenter = () => {
    negative(`/space/${user?.publicId}`);
    onEnterUserCenter?.();
  }
  return <div className={`${theme === 'light' ? styles.navActionsLight : styles.navActions} ${className}`}>
    {/* 帮助文档 */}
    <Button className={styles.textButton} type="text" icon={<BookOutlined />} onClick={() => {
      window.open('/help', '_blank');
    }}>
      <span className={styles.textButtonText}>{t('Documentations')}</span>
    </Button>
    {/* 充值，跳转到 /space/member */}
    <Button className={styles.textButton} type="text" icon={<PayCircleOutlined />} onClick={() => {
      window.open('/space/member', '_blank');
    }}>
      <span className={styles.textButtonText}>{t('Recharge')}</span>
    </Button>
    <div className='flex'>
      <GlobalOutlined style={{ 
        marginRight: -8, 
        zIndex:1,
        color: theme === 'light' ? 'black' : 'white'
      }} />
      <Select 
        className={styles.localeSelect}
        value={locale}
        onChange={(value) => {
          useGlobalStore.setState({ locale: value });
        }}
      >
        <Select.Option value="zh-CN">简体中文</Select.Option>
        <Select.Option value="en-US">English</Select.Option>
      </Select>
    </div>
    {
      user?.publicId 
      ?  <Dropdown menu={{items: [
        {
          label: (
            <Button type="text" onClick={entryUserCenter}>
              {t('UserCenter')}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button type="text" onClick={handleLogout}>
              {t('Logout')}
            </Button>
          ),
          key: '1',
        },
      ]}}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Avatar src={user.avatar}>{ user.avatar ? null : user.email?.slice(0,1)}</Avatar> 
            </Space>
          </a>
        </Dropdown>
      : <div style={{display: 'flex'}}>
        <Button className={styles.signUp} onClick={handleSignUp} type="primary">{t('SignIn')}</Button>
      </div>
    }
  </div>;
}

const FloatingActionButton = ({ setShowContactInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ right: 24, bottom: 100 });
  const buttonRef = useRef(null);
  const isDragging = useRef(false);
  const dragStartPos = useRef({ x: 0, y: 0 });

  const handleDrag = (e) => {
    if (!isDragging.current) return;
    const deltaX = dragStartPos.current.x - e.clientX;
    const deltaY = dragStartPos.current.y - e.clientY;
    const newRight = Math.max(0, Math.min(window.innerWidth - 56, position.right + deltaX));
    const newBottom = Math.max(0, Math.min(window.innerHeight - 56, position.bottom + deltaY));
    setPosition({ right: newRight, bottom: newBottom });
    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  useEffect(() => {
    const handleMouseUp = () => {
      isDragging.current = false;
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [position]);

  const startDrag = (e) => {
    e.preventDefault();
    isDragging.current = true;
    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    if (!isDragging.current) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      ref={buttonRef}
      className={`${styles.floatingActionButton} ${isOpen ? styles.open : ''}`}
      style={{ 
        right: `${position.right}px`, 
        bottom: `${position.bottom}px`,
      }}
    >
      <div 
        className={styles.fabToggle} 
        onClick={handleToggle}
        onMouseDown={startDrag}
      >
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
        </svg>
      </div>
      <div className={styles.fabMenu}>
        <Popover title={null} trigger="hover" placement="left" content={<GroupQrCode />}>
          <button className={`${styles.fabItem} ${styles.qrCode}`}>
            <QrcodeOutlined />
          </button>
        </Popover>
        <a href="https://github.com/jinchanz/antd-stable-diffusion-webui" target="_blank" rel="noopener noreferrer" className={`${styles.fabItem} ${styles.github}`}>
          <GithubOutlined />
        </a>
        <button className={`${styles.fabItem} ${styles.mail}`} onClick={() => setShowContactInfo(true)}>
          <MailOutlined />
        </button>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const locale = useGlobalStore((state) => state.locale);
  
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [unselected, setUnselected] = useState(false);
  const [ t, i18n ] = useTranslation();
  const negative = useNavigate();
  const location = useLocation();
  const contentRef = useRef();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);


  const onMenuSelect = () => {
    setUnselected(false);
  }

  const goHome = () => {
    negative('/');
  }

  return (
    <Layout className="layout">
      <TransparentHeader 
        unselected={unselected} 
        setUnselected={setUnselected} 
        onMenuSelect={onMenuSelect} 
        theme={['/explore'].includes(location.pathname) ? 'dark' : 'light'}
        className="bg-transparent fixed top-0 left-0 right-0 z-50"
      />
      <Content 
        ref={contentRef} 
        style={{ height: '100vh', overflowY: 'scroll', paddingTop: 64 }}
        onScroll={() => {
          setShowBackToTop(contentRef?.current?.scrollTop  > 64);
        }}
      >
        <Outlet />
      </Content>
      {typeof window !== 'undefined' && ReactDOM.createPortal(
        <FloatingActionButton setShowContactInfo={setShowContactInfo} />,
        document.body
      )}
      { showBackToTop ? <FloatButton 
        icon={<VerticalAlignTopOutlined style={{ fontSize: 18 }} />}
        style={{ 
          right: 24, 
          bottom: 24,
          width: 56,
          height: 56,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          color: '#007AFF',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(10px)',
          transition: 'all 0.3s ease',
        }}
        onClick={() => {
          contentRef?.current?.scrollTo?.({
            top: 0,
            behavior: 'smooth',
          });
        }} 
      /> : null }
      <Modal footer={false} title={
        <h1>{t('Contact')}</h1>
      } centered open={showContactInfo} onCancel={setShowContactInfo.bind(null, false)}>
        <div>
          <div className={styles.contactSection} style={{ display: 'flex', flexDirection: 'column', fontSize: 20, fontWeight: 400 }}>
            <span className={styles.contactLabel}>
              {t('Email')} 
            </span>
            <span className={styles.contactContent}>
              june@junemark.tech
            </span>
            <span className={styles.contactContent}>
              mark@junemark.tech
            </span>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default App;
