import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Modal } from 'antd';
import type { MenuProps } from 'antd';
import { useTranslation } from "react-i18next";
import { useGlobalStore } from '@/store';

import styles from './index.module.scss';

type MenuItem = Required<MenuProps>['items'][number];

export interface IComingSoonInfo {
  title: string;
  content: string;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  onClick?: (key: React.Key) => void,
  disabled?: boolean,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
    onClick,
  } as MenuItem;
}

const items = (t, onItemClick) : MenuItem[] => {

  return [
    getItem(t('Home'), ''),
    getItem(t('Explore'), 'explore'),
    getItem(t('Studio'), 'studio', () => {}, false, '', [
      getItem(t('ComfyUI'), 'comfyui'),
      getItem(t('AppBuilder'), 'workflow', onItemClick),
    ]),
  ];
};

export interface INavBarProps {
  mode?: 'horizontal' | 'vertical' | 'inline';
  unselected?: boolean;
  onSelect?: (selectedKeys: string[]) => void;
  theme?: 'light' | 'dark';
}

function NavBar( props: INavBarProps) {
  const {mode, theme, unselected, onSelect: propsOnSelect, ...otherProps} = props || {};
  const [key, setKey] = useState(window.location.pathname.split('/')[1]);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [comingSoonInfo, setComingSoonInfo] = useState<IComingSoonInfo>();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const { t, i18n } = useTranslation();
  const negative = useNavigate();
  const onSelect = ({ selectedKeys }) => {
    propsOnSelect?.(selectedKeys);
    setShowComingSoon(false);
    const selectedKey = selectedKeys?.[0];
    if (!selectedKey) {
      negative(selectedKey);
      setKey(selectedKey);
      return;
    }
    if (selectedKey.includes('train')) {
      setShowComingSoon(true);
      setComingSoonInfo({
        title: t('Train'),
        content: t('ComingSoon'),
      })
    } else if (selectedKey.includes('contact')) {
      // setShowContactInfo(true);
    } else if (selectedKey.includes('design') 
      || selectedKey.includes('tool')
    ) {
      if (!currentUser?.publicId) {
        setKey(key);
        showLoginModal();
      } else {
        negative(selectedKey);
        setKey(selectedKey);
      }
    } else {
      negative(selectedKey);
      setKey(selectedKey);
    }

  };
  useEffect(() => {
    const currentKeyFromPathname = window.location.pathname.split('/')[1];
    setKey(currentKeyFromPathname);
  }, [key])

  // useEffect(() => {
  //   if (!currentUser?.publicId) {
  //     negative('');
  //     setKey('');
  //   }
  // }, [currentUser]);

  return (
    <div className={styles.NavBar}>
      <Menu
        style={{ borderInlineEnd: 'none', backgroundColor: 'transparent'}}
        onSelect={onSelect}
        theme={theme}
        mode={mode}
        defaultSelectedKeys={['']}
        selectedKeys={[unselected ? 'unselected' : key]}
        items={items(t, (key) => {
          negative?.(key?.key);
        })}
        {...otherProps}
      />
      <Modal title={
        <h1>{comingSoonInfo?.title}</h1>
      } footer={false} centered open={showComingSoon} onCancel={setShowComingSoon.bind(null, false)}>
        <span>{t('ComingSoon')}</span>
      </Modal>
    </div>
  );
}

export default NavBar;
