import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CommonUploader from "../CommonUploader";

export interface IAvatarUploaderProps {
  value?: string;
  onChange?: (value: string) => void;
  enableCrop?: boolean;
}


const UploadButton = (loading) => (
  <button style={{ border: 0, background: 'none' }} type="button">
    {loading ? <LoadingOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </button>
);

const AvatarUploader = (props: IAvatarUploaderProps) => {
  const {value, onChange, enableCrop} = props;
  return <CommonUploader 
    value={value}
    onChange={onChange}
    enableCrop={enableCrop}
    listType="picture-circle"
    className="avatar-uploader"
    accept={'.jpg,.png,.heic,.webp,.gif,.jpeg,.svg'}
    uploaderStyle={{ width: '116px', height: '100px' }}
    previewComponent={(value) => <img 
      src={value} 
      alt="avatar" 
      style={{ width: '100px', borderRadius: '50%' }} /> }
    uploadButton={UploadButton}
  />
}

export default AvatarUploader;