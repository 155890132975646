export default {
  /**
   * 素材
   */
  StickerLabel: '标签',
  Use: '使用',
  Home: '首页',
  Design: '设计',
  Train: '模型训练',
  SignIn: '登录',
  SignUp: '免费注册',
  Contact: '联系我们',
  ComingSoon: '奋力研发中，敬请期待~',
  Email: '电子邮箱',
  DigitalTwin: '数字分身',
  Resample: '相似图',
  Rembg: '抠图',
  ToolBox: '工具箱',
  AIPaint: 'AI绘画',
  Profile: '个人资料',
  MyPage: '个人主页',
  Developer: '开发者',
  AIWorkflow: 'AI应用',
  AIApp: 'AI应用',
  AppBuilder: 'App Builder',
  Studio: '工作室',
  Explore: '探索',
  AIAppsBuilder: 'AI应用构建',
  Member: '会员中心',
  MyWorkflow: '我的应用',
  EditorPick: '精选应用',
  UploadWorkflow: '制作AI应用',
  UserCenter: '个人中心',
  Logout: '退出登录',
  AIBoostArt: 'AI助力艺术',
  BornForDesigner: '为新世代 AI 设计者而生',
  NoWorkflow: '暂无应用',
  TXT2IMG: '文生图',
  openApp: '进入应用',
  editApp: '编辑应用',
  FreeTrial: '限时免费',
  Discount: '限时优惠',
  generate: '立即生成',
  generating: '生成中...',
  generateFailed: '生成失败',
  Documentations: '文档',
  Recharge: '充值',
  workflow: {
    app: {
      left: '剩余',
      MCoin: 'M币',
      playground: 'Playground',
      api: 'Open API',
      comingSoon: '敬请期待',
      cost: '本次消耗',
    }
  },
  home: {
    AIWorkflow: 'AI应用',
    AIWorkflowDesc: 'AI应用是一个无需编码即可构建AI应用的工具。',
    AIPainting: 'AI绘画',
    AIPaintingDesc: 'AI 绘画 WebUI，一键保存绘画参数。',
    malette: '摩拉图',
    product: '产品',
    getStarted: '开始使用',
    connect: '关注我们',
    aiPainting: 'AI绘画',
    aiWorkflow: '即用工作流',
    styleFusion: '风格融合',
    stickers: '人像转贴纸',
    tryOn: '模特换装',
    imageTranslate: '漫画汉化',
    winkPortraits: 'Wink 肖像',
    muscleKing: '肌肉大师',
    socialCard: '社交名片生成器',
    CloudComfyUI: '云端 ComfyUI',
    CloudComfyUIDesc: '云端 ComfyUI，免除模型、节点安装烦恼，开箱即用，享极速体验。',
    pricing: '产品定价（敬请期待）',
    Tutorials: '教程（敬请期待）',
    FAQ: '常见问题（敬请期待）',
    comingSoon: '敬请期待',
    sloganTitle: '部署 AI 应用服务',
    sloganCore: '一个工作流',
    sloganTitleEnd: '就能搞定。',
    sloganSubtitle: '从云端 ComfyUI 到完整 AI 应用，一站式解决方案',
    appName: '摩拉图',
    textSticker: 'AI表情包',
    aiHair: "AI换发色",
    stickerMaker: "AI贴纸制作",
    heroTitle: '用 API 驱动 AI 创新',
    heroSubtitle: '从 ComfyUI 工作流到 API 端点，一键部署你的 AI 创意',
    watchDoc: '查看文档',
    features: {
      cloudComfyui: {
        title: '云端 ComfyUI',
        desc: '免除环境配置烦恼，开箱即用的云端工作流编排工具'
      },
      aiApp: {
        title: 'AI 应用构建器',
        desc: '将工作流一键转换为可用的 AI 应用和 API'
      },
      community: {
        title: '活跃社区',
        desc: '探索和使用由社区创建的优质 AI 应用'
      }
    },
    welcomeText: '欢迎来到摩拉图',
    coreFeatures: '核心能力',
    coreFeaturesDesc: '从云端工作流编排到 API 部署，一站式解决方案',
    easyIntegration: '简单集成',
    easyIntegrationDesc: '通过 RESTful API 轻松集成到任何应用中',
    cloudInfra: '云端基础设施',
    cloudInfraDesc: '高性能GPU集群支持，无需担心硬件配置',
    quickDeploy: '快速部署',
    quickDeployDesc: '工作流一键转换为可用的 API 服务',
    popularWorkflows: '热门 AI 应用',
    popularWorkflowsDesc: '探索社区中最受欢迎的 AI 应用',
    txt2img: '文本生成图像',
    txt2imgDesc: '使用自然语言描述生成高质量图像',
    styleTransfer: '风格迁移',
    styleTransferDesc: '将任意图片转换为特定艺术风格',
    imageEdit: '智能图像编辑',
    imageEditDesc: '强大的 AI 驱动图像编辑工具',
    textStickerDesc: '一键生成贴纸风格表情包',
    codeSwitch: {
      javascript: 'JavaScript SDK',
      python: 'Python SDK',
      curl: 'cURL',
      wip: '开发中',
      wipDesc: 'JavaScript SDK 正在开发中，敬请期待',
      soon: '即将推出',
      comingSoon: '即将推出',
      stayTuned: '我们正在为您开发更便捷的 SDK 集成方案'
    },
  },
  loginForm: {
    login: '登录',
    email: '邮箱',
    password: '密码',
    remember: '记住我',
    forget: '忘记密码',
    noAccount: '没有账号？',
    signUp: '免费注册',
    loginFailed: '登录失败',
    welcome: '欢迎来到摩拉图AI工坊',
    signInOrSignUp: '登录/注册',
    sendVerifyCode: '发送验证码',
    sendAgain: '后可发送',
    codeInvalid: '请输入正确的验证码',
    emailInvalid: '请输入正确的邮箱',
    paramInvalid: '请输入正确的邮箱地址和验证码',
    footer: '登录即代表您同意 <term>用户协议</term> 和 <privacy>隐私政策</privacy>，若无账号系统会自动为您创建'
  },
  OpenAPI: {
    APITokenDescription: 'API Token, 在`用户中心 -> 开发者`中获得',
  },
  signAgreementDialog: {
    title: '协议签署',
    content: '请阅读<term>用户协议</term> 和 <privacy>隐私政策</privacy>，点击同意即表示您已阅读并同意，协议将在您使用服务时生效。',
    cancel: '取消',
    agree: '同意',
  },
  PaymentModal: {
    title: '充值提示',
    content: 'M币不足，请充值后使用。',
    cancel: '取消',
    agree: '前往充值',
  },
  ComfyUI: 'ComfyUI',
  common: {
    more: '加载更多',
  },
  "ExploreWorkflows": "探索AI应用",
  "ExploreDescription": "发现和使用由社区创建的优质 AI 应用",
  "NoMoreWorkflows": "已经到底啦 ~",
  "NoWorkflowsYet": "暂无AI应用",
  "NoWorkflowsDescription": "目前还没有任何AI应用，快来创建第一个吧！"
};
