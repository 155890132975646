
import { CommonResponse, ApiResponse, LogoutResponse } from '@/models/common/response';
import { Profile, UserInfo } from '@/models/common/user';
import request from '@/utils/request';

export async function getProfileData(): Promise<CommonResponse<Profile>> {
  const response = await request.get<string, CommonResponse<Profile>>(`/api/v1/me`);
  return response;
}

export async function getUserInfo(): Promise<ApiResponse<UserInfo>> {
  const response = await request.get(`/api/v1/me`);
  return response;
}

export async function logout(): Promise<ApiResponse<LogoutResponse>>{
  const response = await request.get(`/logout`);
  return response;
}

export async function sendVerificationCode(email: string) {
  const response = await request.post('/api/v1/login/verification-code', { email });
  console.log('in service response: ', response);
  return response;
}

export async function signinWithEmail(email: string, code: string) {
  const response = await request.post('/api/v1/login/signinWithEmail', { email, code });
  console.log('in service response: ', response);
  return response;
}

export async function updateProfile(profile: Record<string, any>) {
  const response = await request.put('/api/v1/profile', {
    profile,
  });
  console.log('response: ', response);
  return response;
}


export async function generateAPIToken() {
  const response = await request.put('/api/v1/apiToken', {});
  console.log('response: ', response);
  return response;
}

export async function listMcoinRecords() {
  const response = await request.get('/api/v1/mcoin/records');
  return response;
}

export async function listPaymentProducts() {
  const response = await request.get('/api/v1/payment/products');
  return response;
}

export async function createPaymentOrder(productCode: string) {
  const response = await request.post('/api/v1/payment/order/qrcode', {
    productCode,
  });
  return response;
}

/**
 * SUCCESS：支付成功
  REFUND：转入退款
  NOTPAY：未支付
  CLOSED：已关闭
  REVOKED：已撤销（付款码支付）
  USERPAYING：用户支付中（付款码支付）
  PAYERROR：支付失败(其他原因，如银行返回失败)
 * @param orderId 
 * @returns 
 */
export async function getPaymentOrderStatus(orderId: string) {
  const response = await request.get(`/api/v1/payment/order/status/${orderId}`);
  return response;
}

export async function signAgreement() {
  const response = await request.put('/api/v1/agreement/sign', {});
  return response;
}