import { OssTOken } from '@/models/oss';

function getFileExtension(file: File) {
  return file.name.split('.').pop();
}

export async function uploadFileToOSS(file: File, ossToken: OssTOken) {
  return new Promise((resolve, reject) => {
    const { path, accessid, policy, signature, callback } = ossToken;
    const result = `${path}/${Date.now()}.${getFileExtension(file)}`;
    const formData = new FormData();
    formData.append('key', result);
    formData.append('OSSAccessKeyId', accessid);
    formData.append('policy', policy);
    formData.append('Signature', signature);
    formData.append('callback', callback);
    // 根据实际情况添加其他必要的字段

    // 追加要上传的文件
    formData.append('file', file);

    // 发送上传请求
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://ablula.oss-cn-shenzhen.aliyuncs.com', true);

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log('Upload success:', xhr.responseText);
        resolve(result)
      } else {
        console.error('Upload failed:', xhr.status, xhr.statusText);
        reject(new Error(xhr.statusText));
      }
    };

    xhr.onerror = function () {
      console.error('Network error');
      reject(new Error('Network error'));
    };

    xhr.send(formData);
  });
}