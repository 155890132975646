

export interface IWorkflowItemProps {
  item: any;
  onSelected?: (item: any) => void;
  selected?: boolean;
}

const WorkflowItem = (props: IWorkflowItemProps) => {
  const { item, onSelected, selected } = props;
  return (
    <div 
      className={`flex py-2 px-1 cursor-pointer hover:bg-slate-100 ${selected ? 'bg-slate-200' : ''}`} 
      onClick={() => onSelected && onSelected(item)}
    >
      <div className="h-24 flex w-full gap-4">
      <img className="h-full" src={item.cover} alt={item.name} />
      <div className="flex flex-col p">
          <div className="text-sm font-medium truncate">{item.name}</div>
          <div className="text-xs text-gray-500 line-clamp-2">{item.description}</div>
        </div>
      </div>
    </div>
  );
}

export default WorkflowItem;