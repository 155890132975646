import { WorkflowItem } from "@/constants";
import { Button, Divider } from "antd";
import moment from "moment";

export interface AppHomepageProps { 
  open: boolean;
  workflow?: WorkflowItem;
  onContentTypeChange?: (contentType: 'creator' | 'homepage' | 'guide') => void;
}

const AppHomepage = (props  : AppHomepageProps) => {
  const { open, workflow, onContentTypeChange } = props;
  if (!open || !workflow) return null;
  return (
    <div>
      <div className="flex flex-row gap-4 justify-between">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col h-40 w-40">
            <img className="w-full h-full object-cover rounded-lg" src={workflow?.cover} alt={workflow?.name} />
          </div>
          <div className="flex flex-col gap-2 justify-between py-1">
            <div className="flex flex-row gap-4">
              {/* <span className="text-lg font-bold text-gray-500 w-16 text-right">标题</span> */}
              <span className="text-lg">{workflow?.name}</span>
            </div>
            <div className="flex flex-row gap-4">
              {/* <span className="text-sm font-bold text-gray-500 w-16 text-right">描述</span> */}
              <span>{workflow?.description}</span>
            </div>
            <div className="flex flex-row gap-4">
              <span className="text-sm font-bold text-gray-500">创建时间</span>
              <span>{moment(workflow?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
            <div className="flex flex-row gap-4">
              <span className="text-sm font-bold text-gray-500">更新时间</span>
              <span>{moment(workflow?.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
            <div className="flex flex-row gap-4">
              <span className="text-sm font-bold text-gray-500">主页链接</span>
              <a href={`https://${window.location.hostname}/workflow/${workflow?.code}`} target="_blank" rel="noopener noreferrer">
                {`https://${window.location.hostname}/workflow/${workflow?.code}`}
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-end items-end">
          <Button type="primary" onClick={() => onContentTypeChange('creator')}>
            修改配置
          </Button>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-4">
        <iframe src={`https://${window.location.hostname}/nohead/workflow/${workflow?.code}`} className="w-full h-[calc(100vh-320px)]" />
      </div>
    </div>
  );
};

export default AppHomepage;