import moment from "moment";
import { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Button, UploadProps, message } from "antd";

import { OssTOken } from "@/models/oss";
import Dragger from "antd/es/upload/Dragger";
import { useGlobalStore } from "@/store";

export interface IFileUploaderProps extends UploadProps {
  value?: string;
  onChange?: (value: string) => void;
  previewComponent?: (value: string, clear: () => void) => React.ReactNode;
  uploadButton?: (loading) => React.ReactNode;
  uploaderStyle?: React.CSSProperties;
}

const MAX_FILE_SIZE_LIMIT = 50 * 1024 * 1024;


const FileUploader = forwardRef((props: IFileUploaderProps, ref) => {
  const {value, onChange, previewComponent, uploadButton, uploaderStyle, ...uploaderProps} = props;
  const [loading, setLoading] = useState(false);
  const [ossToken, setOssToken] = useState<OssTOken>();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const showLoginModal = useGlobalStore((state) => state.showLoginModal);
  const now = moment(new Date());

  const init = useCallback(async () => {
    try {
      const tokenResponse = await fetch('/api/v1/oss/token');
      const initOssToken: OssTOken = (await tokenResponse.json()).data;
      setOssToken(initOssToken);
    } catch (error) {
      messageApi.error(error);
    }
  }, [messageApi]);

  useEffect(() => {
    init();
  }, [init, currentUser?.publicId]);

  const beforeUpload = async (file) => {
    if (!currentUser?.publicId) {
      showLoginModal(false);
      return false;
    }

    if (file.size > MAX_FILE_SIZE_LIMIT) {
      messageApi.error(`文件超过限制 ${MAX_FILE_SIZE_LIMIT/(1024*1024)}MB`);
      return false;
    }
    
    if (!ossToken) {
      console.error('ossToken is not ready');
      return false;
    }
    setLoading(true);
    const expire = Number(ossToken.expire) * 1000;
  
    if (expire < Date.now()) {
      await init();
    }
  
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return file;
  };
  
  const getExtraData: UploadProps['data'] = (file) => {
    file.url = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
    return {
      key: file.url,
      OSSAccessKeyId: ossToken?.accessid,
      policy: ossToken?.policy,
      signature: ossToken?.signature,
      success_action_status: 200,
    };
  };

  const handleClear = () => {
    setLoading(false);
    onChange?.('');
  };

  useImperativeHandle(ref, () => ({
    handleChange: async (info: any) => {
      console.log('FileUploader handleChange 被调用:', info);
      const file = info.file;
      
      // 检查用户登录状态
      if (!currentUser?.publicId) {
        showLoginModal(false);
        return;
      }

      // 检查文件大小
      if (file.size > MAX_FILE_SIZE_LIMIT) {
        messageApi.error(`文件超过限制 ${MAX_FILE_SIZE_LIMIT/(1024*1024)}MB`);
        return;
      }

      // 确保 ossToken 存在
      if (!ossToken) {
        console.error('ossToken is not ready');
        messageApi.error('上传服务未就绪，请稍后重试');
        return;
      }

      try {
        // 检查 token 是否过期
        const expire = Number(ossToken.expire) * 1000;
        if (expire < Date.now()) {
          await init();
        }

        // 设置文件路径
        const fileUrl = `sd-online/images/${now.format('YYYY/MM/DD')}/${file.uid}${file.name.slice(file.name.lastIndexOf('.'))}`;
        
        // 准备上传参数
        const formData = new FormData();
        formData.append('key', fileUrl);
        formData.append('OSSAccessKeyId', ossToken.accessid);
        formData.append('policy', ossToken.policy);
        formData.append('signature', ossToken.signature);
        formData.append('success_action_status', '200');
        formData.append('file', file.originFileObj || file);

        // 执行上传
        console.log('开始上传文件到OSS:', fileUrl);
        const response = await fetch(ossToken.host, {
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          console.log('文件上传成功');
          // 构建完整的文件URL
          const fullUrl = `https://ablula.oss-accelerate.aliyuncs.com/${fileUrl}`;
          onChange?.(fullUrl);
        } else {
          throw new Error('上传失败');
        }
      } catch (error) {
        console.error('文件上传失败:', error);
        messageApi.error('文件上传失败');
      }
    }
  }));

  return <div style={{...uploaderStyle}}>
    {contextHolder}
    { value 
        ? previewComponent?.(value, handleClear)
        : <Dragger
            name="file"
            action={ossToken?.host}
            beforeUpload={beforeUpload}
            data={getExtraData}
            {...uploaderProps}
            onChange={(file) => {
              console.log('Dragger onChange:', file.file.name);
              if (file.file.status === 'done' && file.file.url) {
                onChange?.(`https://ablula.oss-accelerate.aliyuncs.com/${file.file.url}`)
              }
            }}
          >
            {uploadButton ? uploadButton(loading) : <Button type="text">上传文件</Button>}
          </Dragger>
    }
  </div>;
})

export default FileUploader;