import { Footer } from 'antd/es/layout/layout';
import { Button, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApiOutlined, CloudServerOutlined, ThunderboltOutlined, BilibiliOutlined, TikTokOutlined, WechatOutlined, XOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { Terminal } from '@/components/Terminal';
import { WorkflowPreview } from '@/components/WorkflowPreview';
import GroupQrCode from '@/components/Feedback/GroupQRCode';
import { isChineseDomain } from "@/utils";
import { TransparentHeader } from '../layout';
import { useState } from 'react';
const isChineseDomainFlag = isChineseDomain();

function Home() { 
  const { t } = useTranslation();
  const [codeType, setCodeType] = useState('javascript');

  const handleGetStarted = () => {
    window.open('/explore', '_blank');
  }

  // JavaScript 示例代码
  const jsCode = `
const { Malette } = require('@malette/sdk');
// initialize client
const client = new Malette({
  apiKey: 'your-api-key',
  // endpoint: 'custom-endpoint' // optional, default to https://api.malette.art
});
// run workflow synchronously
async function runWorkflow() {
  try {
    const result = await client.runSync('TXT2IMG', {
      // workflow parameters
      "text6": "一只可爱的中国龙",
      "batch_size5": "1",
      "width5": "1024",
      "height5": "1024",
      "ckpt_name4": "ArcadeXL_v0.2.safetensors"
    }, 
    {
        timeout: 60 // optional, timeout (seconds)
    });
    console.log('workflow execution result:', result);
  } catch (error) {
    console.error('execution error:', error);
  }
}
  `.trim();

  // cURL 示例代码
  const curlCode = `
curl -X POST https://api.malette.art/open/api/v1/workflow/TXT2IMG \
-H "Authorization: Bearer viMa3MrAKaaPUCPcSxekR" \
-H "Content-Type: application/json" \
-d '{
  "text6": "一只可爱的猫咪",
  "batch_size5": "1",
  "width5": "1024",
  "height5": "1024",
  "ckpt_name4": "ArcadeXL_v0.2.safetensors"
}'
`.trim();

  // 增加 Python 示例代码
  const pythonCode = `
from malette import Malette

# initialize client
client = Malette(
    api_key='your-api-key',
    # endpoint='custom-endpoint'  # 可选，默认为 https://api.malette.art
)

# run workflow synchronously
async def run_workflow():
    try:
        result = await client.run_sync('TXT2IMG', {
          "text6": "一只可爱的猫咪",
          "batch_size5": "1",
          "width5": "1024",
          "height5": "1024",
          "ckpt_name4": "ArcadeXL_v0.2.safetensors"
        }, 
        timeout=60  # optional, timeout (seconds)
        )
        print('workflow execution result:', result)
    except Exception as error:
        print('execution error:', error)
`.trim();

  // 根据当前选择返回对应代码
  const demoCode = {
    'javascript': jsCode,
    'python': pythonCode,
    'curl': curlCode
  }[codeType];

  return (
    <div className="bg-black h-screen overflow-y-scroll">
      <TransparentHeader 
        unselected={false} 
        setUnselected={() => {}} 
        onMenuSelect={() => {}} 
        className="bg-transparent"
      />
      <div className="min-h-screen bg-black">
        {/* Hero Section */}
        <div className="relative bg-black">
          {/* 背景效果 */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(91,33,182,0.15),transparent_50%)]" />
            <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_bottom,rgba(219,39,119,0.15),transparent_50%)]" />
            <div className="absolute inset-0 bg-grid-white/[0.03] bg-[size:50px_50px]" />
          </div>

          {/* 主要内容 */}
          <div className="relative  max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32">
            {/* Hero Text Section */}
            <div className="text-center mb-16">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {/* <h2 className="text-purple-400 text-xl font-medium tracking-wide mb-6">
                  {t('home.welcomeText')}
                </h2> */}
                <h1 className="text-5xl md:text-7xl font-bold tracking-tight leading-tight mb-8 mt-36">
                  <span className="text-white block mb-2">
                    {t('home.sloganTitle')}
                  </span>
                  <span className="bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600 bg-clip-text text-transparent inline-block">
                    {t('home.sloganCore')}
                  </span>
                  <span className="text-white">
                    {t('home.sloganTitleEnd')}
                  </span>
                </h1>
                <p className="text-xl text-gray-400 max-w-2xl mx-auto leading-relaxed mb-8">
                  {t('home.sloganSubtitle')}
                </p>
                
                <div className="flex justify-center gap-6">
                  <Button 
                    onClick={handleGetStarted} 
                    size="large"
                    className="h-14 px-10 text-lg font-medium rounded-full bg-gradient-to-r from-purple-600 to-pink-600 text-white border-0 hover:shadow-lg hover:shadow-purple-500/40 transition-all duration-300 hover:scale-105"
                  >
                    {t('home.getStarted')}
                  </Button>
                  <Button 
                    size="large"
                    className="h-14 px-10 text-lg font-medium rounded-full bg-white/10 backdrop-blur-sm text-white hover:bg-white/20 transition-all duration-300 border-0 hover:scale-105"
                    onClick={() => window.open('/help/boeogh2tqvwaug6f', '_blank')}
                  >
                    {t('home.watchDoc')}
                  </Button>
                </div>
              </motion.div>
            </div>

            {/* Demo Section */}
            <div className="grid lg:grid-cols-2 gap-12 items-center mb-32 overflow-hidden">
              {/* 左侧: 工作流预览 */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="relative w-full"
              >
                <div className="relative rounded-lg overflow-hidden shadow-2xl">
                  <WorkflowPreview className="w-full" />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent" />
                </div>
                {/* 装饰元素 */}
                <div className="absolute -top-4 -left-4 w-24 h-24 bg-purple-500/20 rounded-full blur-2xl" />
                <div className="absolute -bottom-4 -right-4 w-32 h-32 bg-pink-500/20 rounded-full blur-2xl" />
              </motion.div>

              {/* 右侧: 代码示例 */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <div className="mb-6 flex justify-end">
                  <div className="inline-flex p-1 bg-white/5 backdrop-blur-sm rounded-xl">
                    <button
                      onClick={() => setCodeType('curl')}
                      className={`px-4 py-2 text-sm font-medium rounded-lg transition-all duration-300 ${
                        codeType === 'curl'
                          ? 'bg-gradient-to-r from-purple-600/20 to-pink-600/20 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-white/10'
                      }`}
                    >
                      {t('home.codeSwitch.curl')}
                    </button>
                    <button
                      onClick={() => setCodeType('javascript')}
                      className={`px-4 py-2 text-sm font-medium rounded-lg transition-all duration-300 ${
                        codeType === 'javascript'
                          ? 'bg-gradient-to-r from-purple-600/20 to-pink-600/20 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-white/10'
                      }`}
                    >
                      {t('home.codeSwitch.javascript')}
                    </button>
                    <Popover 
                      content={
                        <div className="p-2 max-w-[200px] text-center">
                          <div className="text-purple-400 font-medium mb-1">✨ {t('home.codeSwitch.wip')} ✨</div>
                          <div className="text-gray-400 text-sm">{t('home.codeSwitch.wipDesc')}</div>
                        </div>
                      }
                      placement="top"
                      trigger={codeType === 'python' ? 'hover' : []}
                    >
                      <button
                        onClick={() => setCodeType('python')}
                        className={`px-4 py-2 text-sm font-medium rounded-lg transition-all duration-300 ${
                          codeType === 'python'
                            ? 'bg-purple-500/20 text-purple-400 cursor-not-allowed'
                            : 'text-gray-400 hover:text-white hover:bg-white/10'
                        }`}
                        disabled={codeType === 'python'}
                      >
                        {t('home.codeSwitch.python')}
                        <span className="ml-1 text-xs px-1.5 py-0.5 rounded bg-purple-500/20 text-purple-400">
                          {t('home.codeSwitch.soon')}
                        </span>
                      </button>
                    </Popover>
                  </div>
                </div>
                <div className="relative">
                  <Terminal 
                    code={demoCode} 
                    language={codeType === 'curl' ? 'bash' : codeType} 
                  />
                  {codeType === 'python' && (
                    <div className="absolute inset-0 backdrop-blur-sm bg-black/40 rounded-lg flex items-center justify-center">
                      <div className="text-center">
                        <div className="text-2xl font-medium text-purple-400 mb-2">
                          ✨ {t('home.codeSwitch.comingSoon')} ✨
                        </div>
                        <div className="text-gray-400">
                          {t('home.codeSwitch.stayTuned')}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>

            {/* Features Grid */}
            <div className="mb-32">
              <div className="text-center mb-16">
                <motion.h2
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="text-4xl font-bold text-white mb-6"
                >
                  {t('home.coreFeatures')}
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="text-xl text-gray-400 max-w-3xl mx-auto"
                >
                  {t('home.coreFeaturesDesc')}
                </motion.p>
              </div>

              <div className="grid md:grid-cols-3 gap-8">
                {/* Feature Cards */}
                <FeatureCard
                  icon={<ApiOutlined className="w-6 h-6" />}
                  title={t('home.easyIntegration')}
                  description={t('home.easyIntegrationDesc')}
                  delay={0.2}
                />
                <FeatureCard
                  icon={<CloudServerOutlined className="w-6 h-6" />}
                  title={t('home.cloudInfra')}
                  description={t('home.cloudInfraDesc')}
                  delay={0.4}
                />
                <FeatureCard
                  icon={<ThunderboltOutlined className="w-6 h-6" />}
                  title={t('home.quickDeploy')}
                  description={t('home.quickDeployDesc')}
                  delay={0.6}
                />
              </div>
            </div>

            {/* Workflow Showcase */}
            <div className="mb-32">
              <div className="text-center mb-16">
                <motion.h2
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="text-4xl font-bold text-white mb-6"
                >
                  {t('home.popularWorkflows')}
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="text-xl text-gray-400 max-w-3xl mx-auto"
                >
                  {t('home.popularWorkflowsDesc')}
                </motion.p>
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
              >
                <WorkflowCard
                  href="/workflow/TXT2IMG"
                  title={t('home.txt2img')}
                  description={t('home.txt2imgDesc')}
                  image="https://i.ablula.tech/sd-online/images/2024/03/23/rc-upload-1711138338889-4.jpeg!medium"
                />
                <WorkflowCard
                  href="/workflow/STYLE_FUSION"
                  title={t('home.styleTransfer')}
                  description={t('home.styleTransferDesc')}
                  image="https://i.ablula.tech/sd-online/images/2024/03/23/rc-upload-1711132980165-4.jpeg!medium"
                />
                <WorkflowCard
                  href="/workflow/text-sticker"
                  title={t('home.textSticker')}
                  description={t('home.textStickerDesc')}
                  image="https://i.ablula.tech/sd-online/images/2024/03/23/rc-upload-1711125317330-4.png!medium"
                />
              </motion.div>
            </div>
          </div>
        </div>
        {/* Info Section */}
        <div className="relative bg-gradient-to-b from-black via-gray-900/95 to-gray-900 text-white">
          {/* 添加渐变装饰 */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,rgba(91,33,182,0.08),transparent_70%)]" />
            <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:30px_30px]" />
          </div>

          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
            <div className="grid md:grid-cols-4 gap-16">
              {/* Logo Section */}
              <div>
                <div className="flex items-center space-x-3 mb-8 group">
                  <div className="w-12 h-12 relative">
                    <img src="https://ablula.oss-accelerate.aliyuncs.com/malette/assets/logo.png!xs" 
                         alt="Logo" 
                         className="w-full h-full relative z-10" />
                    <div className="absolute inset-0 bg-gradient-to-r from-purple-600/60 to-pink-600/60 blur-xl group-hover:blur-2xl transition-all duration-500" />
                  </div>
                  <span className="text-2xl font-medium bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent">
                    {t('home.malette')}
                  </span>
                </div>
              </div>

              {/* Products Section */}
              <div>
                <h3 className="text-xl font-medium mb-8 bg-gradient-to-r from-white to-white/90 bg-clip-text text-transparent">
                  {t('home.product')}
                </h3>
                <div className="space-y-4">
                  <a href="/workflow/d7c3573b77de45b" 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     className="block text-gray-400/90 hover:text-white transition-all duration-300 hover:translate-x-1">
                    {t('home.muscleKing')}
                  </a>
                  <a href="/workflow/text-sticker" 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     className="block text-gray-400/90 hover:text-white transition-all duration-300 hover:translate-x-1">
                    {t('home.textSticker')}
                  </a>
                  <a href="/workflow/sticker-maker" 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     className="block text-gray-400/90 hover:text-white transition-all duration-300 hover:translate-x-1">
                    {t('home.stickerMaker')}
                  </a>
                  <a href="/workflow/5891d8d5d83848c" 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     className="block text-gray-400/90 hover:text-white transition-all duration-300 hover:translate-x-1">
                    {t('home.winkPortraits')}
                  </a>
                  <a href="/workflow/ea8c659d77d5401" 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     className="block text-gray-400/90 hover:text-white transition-all duration-300 hover:translate-x-1">
                    {t('home.socialCard')}
                  </a>
                  <a href="/comfyui" 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     className="block text-gray-400/90 hover:text-white transition-all duration-300 hover:translate-x-1">
                    {t('home.CloudComfyUI')}
                  </a>
                </div>
              </div>

              {/* Get Started Section */}
              <div>
                <h3 className="text-xl font-medium mb-8 bg-gradient-to-r from-white to-white/90 bg-clip-text text-transparent">
                  {t('home.getStarted')}
                </h3>
                <div className="space-y-4">
                  <span className="block text-gray-400/90 hover:text-white transition-all duration-300 cursor-pointer hover:translate-x-1">
                    {t('home.pricing')}
                  </span>
                  <span className="block text-gray-400/90 hover:text-white transition-all duration-300 cursor-pointer hover:translate-x-1">
                    {t('home.Tutorials')}
                  </span>
                  <span className="block text-gray-400/90 hover:text-white transition-all duration-300 cursor-pointer hover:translate-x-1">
                    {t('home.FAQ')}
                  </span>
                </div>
              </div>

              {/* Connect Section */}
              <div>
                <h3 className="text-xl font-medium mb-8 bg-gradient-to-r from-white to-white/90 bg-clip-text text-transparent">
                  {t('home.connect')}
                </h3>
                <div className="flex space-x-8">
                  <Popover content={<GroupQrCode />} placement="top">
                    <WechatOutlined className="text-2xl text-gray-400/90 hover:text-white transition-all duration-300 hover:scale-110 cursor-pointer" />
                  </Popover>
                  <a href="https://space.bilibili.com/6962628" target="_blank" rel="noopener noreferrer">
                    <BilibiliOutlined className="text-2xl text-gray-400/90 hover:text-white transition-all duration-300 hover:scale-110" />
                  </a>
                  <a href="https://x.com/jinchanzx" target="_blank" rel="noopener noreferrer">
                    <XOutlined className="text-2xl text-gray-400/90 hover:text-white transition-all duration-300 hover:scale-110" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        {isChineseDomainFlag && (
          <Footer className="bg-gray-900/30 backdrop-blur-sm text-gray-400/90 py-8 text-center border-t border-white/[0.08]">
            <div className="hover:text-white/90 transition-colors">
              Malette.Art ©{new Date().getFullYear()} 版权所有 
              |&nbsp; <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" className="hover:text-white transition-colors">蜀ICP备2023029381号</a> 
              &nbsp;|&nbsp; ICP许可证 川B2-20240572
            </div>
          </Footer>
        )}
      </div>
    </div>
  );
}

// Feature Card Component
function FeatureCard({ icon, title, description, delay = 0 }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      className="relative group p-8 rounded-2xl bg-gradient-to-b from-white/[0.08] to-transparent border border-white/[0.1] hover:border-purple-500/50 transition-all duration-300"
    >
      <div className="absolute inset-0 rounded-2xl bg-gradient-to-b from-purple-600/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      <div className="relative space-y-4">
        <div className="w-12 h-12 rounded-full bg-purple-500/20 flex items-center justify-center text-purple-400">
          {icon}
        </div>
        <h3 className="text-xl font-semibold text-white">
          {title}
        </h3>
        <p className="text-gray-400 leading-relaxed">
          {description}
        </p>
      </div>
    </motion.div>
  );
}

// Workflow Card Component
function WorkflowCard({ title, description, image, href }) {
  return (
    <div 
      className="group relative rounded-2xl overflow-hidden bg-white/5 hover:bg-white/10 transition-all duration-300 cursor-pointer" 
      onClick={() => window.open(href, '_blank')} 
    >
      <div className="aspect-video overflow-hidden">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
}

export default Home;
