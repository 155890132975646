
export const MAX_FILE_SIZE_LIMIT = 10 * 1024 * 1024;

export const DEFAULT_PARAM_NODE_TYPE = {
  KSampler: 1,
  EmptyLatentImage: 1,
  CLIPTextEncode: 1,
  LoadImage: 1,
  Base64ImageInput: 1,
  'Seed Generator': 1,
  BatchPromptScheduleLatentInput: 1,
  'Text box': 1,
  ImagePadForOutpaint: 1,
  'KSampler (Efficient)': 1,
  'CR Upscale Image': 1,
  ListSunoSongs: 1,
  GenSong: 1,
  'Efficient Loader': 1,
  SUPIR_Upscale: 1,
  Fooocus_KSamplerEfficient: 1,
  'Simple String': 1,
  'AnyText': 1,
  CLIPTextEncodeSD3: 1,
  EmptySD3LatentImage: 1,
  'Control Net Stacker': 1,
  'ControlNetApplyAdvanced': 1,
  'IPAdapterAdvanced': 1,
  'IPAdapterStyleComposition': 1,
};

export const DEFAULT_CHECKPOINT_NODE_TYPE = {
  CheckpointLoaderSimple: 1,
  ImageOnlyCheckpointLoader: 1,
  'Efficient Loader': 1,
  'Eff. Loader SDXL': 1,
  'unCLIPCheckpointLoader': 1,
};

export const DEFAULT_LORA_NODE_TYPE = {
  LoraLoader: 1,
  'Efficient Loader': 1,
  'LoRA Stacker': 1,
};

export const DEFAULT_OUTPUT_NODE_TYPE = {
  SaveImage: 1,
  PreviewImage: 1,
  Base64ImageOutput: 1,
  SaveAnimatedWEBP: 1,
  VHS_VideoCombine: 1,
  Base64AnimatedWEBP: 1,
  ListSunoSongs: 1,
  GenSong: 1,
};

export const DEFAULT_CONTENT_NODE_TYPE = {
  ControlNetLoader: 1,
};

export const WEIGHT_KEYS = [ 
  'weight', 
  'strength', 
  'denoise', 
  'start_percent', 
  'end_percent', 
  'lora_model_strength',
  'weight_style', 
  'weight_composition'
];
