import { checkScanResult, createLoginQRCode } from "@/services/Wechat"
import { Button, Form, Input, Modal, ModalProps, message } from "antd"
import cls from "classnames/bind";
import { useCallback, useEffect, useState } from "react"

import styles from './index.module.scss';
import { getExpireItemFromLocalStorage, setExpireItemFromLocalStorage, sleep } from "@/utils";
import { useGlobalStore } from '@/store'
import { sendVerificationCode, signinWithEmail } from "@/services/user";
import { UserInfo } from "@/models/common/user";
import { isChineseDomain } from "@/utils";
import { Trans, useTranslation } from "react-i18next";

const isChineseDomainFlag = isChineseDomain();
const cx = cls.bind(styles);

export interface ILoginDialogProps extends ModalProps {
  onLoginSucceed?: (user?: UserInfo) => void; 
}

export interface IScanResult extends UserInfo{
  publicId: string;
}

let originCount = +getExpireItemFromLocalStorage('EMAIL_CODE');

const WechatLoginForm = ({ wechatQRCode }) => {

  return <div className={cx('wechatLoginForm')}>
    <div id="code-container" className={styles.qrcodeCantainer}>
      <img src={wechatQRCode} />
    </div>
    <div className={styles.qrcodeDesc}>打开 微信 扫一扫登录</div>
  </div>
}

const EmailLoginForm = ({ onLoginSucceed }) => {
  const [count, setCount] = useState<number>(originCount);
  const [loading, setLoading] = useState(false);
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const tiktok = useCallback((time?) => {
    if (time) originCount = time;
    setCount(originCount);
    setTimeout(() => {
      const handle = setInterval(() => {
        if (originCount <= 0 && handle) {
          clearInterval(handle);
        }
        originCount--;
        setCount(originCount);
        setExpireItemFromLocalStorage('EMAIL_CODE', originCount + '', originCount * 1000)
      }, 1000);
    });
  }, []);
  const handleLogin = async () => {
    const { email, code } = form.getFieldsValue();
    form.validateFields(['email', 'code']);
    if (!email || !code) {
      message.error(t('loginForm.paramInvalid'))
      return;
    }
    setLoading(true);
    const result = await signinWithEmail(email, code);
    console.log('result: ', result);
    if (result?.success) {
      getUserInfo();
      onLoginSucceed?.();
    } else {
      message.error(result?.message || t('loginForm.paramInvalid'))
    }
    setLoading(false);
  }
  const handleSendCode = async () => {
    const { email } = form.getFieldsValue();
    form.validateFields(['email']);
    if (!email) {
      return;
    }
    tiktok(60);
    await sendVerificationCode(email);
  } 

  return <div className={cx('emailLoginForm')}>
    <div className={cx('container')}>
      <div className={cx('imgWrapper')}>
        <img src="https://ablula.oss-accelerate.aliyuncs.com/malette/assets/Maltette_neon.png" />
        <div className={cx('desc')}>{t('loginForm.welcome')}</div>
      </div>
      <Form form={form}>
        <div className={styles.item}>
          <Form.Item
            className={styles.input}
            name="email"
            label=""
            rules={[{ required: true, type: 'email', message: t('loginForm.emailInvalid') }]}
          >
            <Input 
              size="large"
              placeholder="Email:" 
            />
          </Form.Item>
        </div>
        <Form.Item
          name="code"
          rules={[{ required: true, message: t('loginForm.codeInvalid') }]}
        ><Input
          size="large"
          placeholder="Code:"
          className={styles.input}
          addonAfter={
            <Button
              size="small"
              type="text"
              loading={count > 0}
              disabled={count > 0}
              onClick={handleSendCode}
              htmlType="submit"
            >{count > 0 ? `${count}  ${t('sendAgain')}` : t('loginForm.sendVerifyCode')}</Button>
          }
        />
        </Form.Item>
        <Button
          size="large"
          style={{ width: '100%' }}
          type="primary"
          loading={loading}
          disabled={loading}
          onClick={handleLogin}
        >{t('loginForm.signInOrSignUp')}</Button>
      </Form>
    </div>
  </div>
}

const LoginDialog = (props: ILoginDialogProps) => {
  const { open, onCancel, onLoginSucceed, ...dialogProps } = props;
  const [ scanResult, setScanResult ] = useState<IScanResult>();
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);

  const [wechatQRCode, setWechatQRCode] = useState('https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGr8DwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyd1ZFdUJvcmtmS0cxR2Q3MU5CMXIAAgRhhoFlAwQsAQAA');

  useEffect(() => {
    if (scanResult?.publicId) {
      onLoginSucceed?.(scanResult);
    }
  }, [scanResult]);

  const loopCheckScanResult = useCallback(async () => {
    if (open && window.currentLoopScanKey && !scanResult) {
      const res = await checkScanResult(window.currentLoopScanKey);
      if (!res?.data?.publicId && window.currentLoopScanKey) {
        await sleep(2000);
        await loopCheckScanResult();
      }
      if (res?.data?.publicId) {
        setScanResult(res?.data)
        getUserInfo();
      }
    }
  }, [getUserInfo, open, scanResult])

  const handleLoginSucceed = () => {
    getUserInfo();
    onLoginSucceed?.();
  }

  useEffect(() => {
    console.log('open: ', open);
    async function fetchQRcode() {
      const res = await createLoginQRCode();
      const { key, qrCodeUrl } = res?.data || {};
      if (qrCodeUrl) {
        setWechatQRCode(qrCodeUrl);
      }
      if (key) {
        window.currentLoopScanKey = key;
        loopCheckScanResult();
      }
    }

    if (open) {
      fetchQRcode();
    }

  }, [open]);

  return <Modal 
    className={cx('LoginDialog')}
    open={open} 
    {...dialogProps}
    onCancel={(e) => {
      onCancel?.(e)
      window.currentLoopScanKey = '';
    }}
    footer={false}
    afterClose={() => {
      window.currentLoopScanKey = '';
    }}
    centered
    style={{ backgroundColor: 'transparent' }}
  >
    <div className={cx('contentWrapper')}>
      <div className={cx('content')}>
        <div className={cx('left')}>
          <EmailLoginForm onLoginSucceed={handleLoginSucceed} />
        </div>
        { isChineseDomainFlag ? <>
          <div className={cx('verDivider')} />
          <div className={cx('right')}>
            <WechatLoginForm wechatQRCode={wechatQRCode} />
          </div>
        </>
        : null }
      </div>
      <div className={cx('footer')}>
        <Trans i18nKey="loginForm.footer" components={{ 
            term: <a target="_blank" href={`/static/${isChineseDomainFlag ? '' : 'en/'}agreement`}></a>,
            privacy: <a target="_blank" href={`/static/${isChineseDomainFlag ? '' : 'en/'}privacy`}></a>
          }} 
          // values={{ appName: t('home.appName') }}
        />
      </div>
    </div>
    
  </Modal>;
}

export default LoginDialog;

