import { Menu } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import type { MenuProps } from 'antd';
import styles from './index.module.scss';
import { useState } from "react";
import { useGlobalStore } from "@/store";
import { useTranslation } from "react-i18next";
import { isChineseDomain } from "@/utils";

const isChineseDomainFlag = isChineseDomain();

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  disabled?: boolean,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled
  } as MenuItem;
}

const getItems = (t, userId) : MenuItem[] => {
  if (isChineseDomainFlag) {
    return [
      getItem(t('MyPage'), userId),
      getItem(t('Member'), 'member'),
      getItem(t('Profile'), 'profile'),
      getItem(t('Developer'), 'developer'),
    ];
  }
  return [
    getItem(t('MyPage'), userId),
    getItem(t('Profile'), 'profile'),
    getItem(t('Developer'), 'developer'),
  ]; 
};

const UserCenter = () => {
  const negative = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState(location.pathname.split('/').pop());
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ t ] = useTranslation();
  const items = getItems(t, currentUser?.publicId);
  console.log('items: ', items);
  const onSelect = ({ selectedKeys }) => {
    const selectedKey = selectedKeys?.[0];
    negative(selectedKey);
    setKey(selectedKey);
  };

  return <div className={styles.UserCenter}>
  <div className={styles.wrapper}>
    <Menu className={styles.menu} 
      onSelect={onSelect}
      defaultSelectedKeys={[key || currentUser?.publicId]}
      items={items}
    />
    <div className={styles.main}>
      <Outlet />
    </div>
  </div>
  </div>
}

export default UserCenter;