export default {
	storageKey: {
		token: "auth-token",
		themeConfig: "theme-config",
		i18nLocal: "i18n-local",
		userInfo: "user-info",
		tags: "tags",
	},
	cookieKey: {
		token: "auth-token",
	},
	mittBusKey: {},
};