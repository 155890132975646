import { WorkflowItem } from "@/constants"; 
import { useEffect, useState } from "react";
import AppWorkflowPreview from "./AppWorkflowPreview";
import { listMaletteWorkflowsByPage } from "@/services/Workflow";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Empty } from "antd";

export interface IComfyUIWorkflowPickerProps {
  workflow?: WorkflowItem;
  onSelect?: (workflow: WorkflowItem | null) => void;
}

export interface IComfyUIWorkflowPickerItemProps {
  workflow: WorkflowItem;
  selected: boolean;
  onSelect: (workflow: WorkflowItem) => void;
}

const ComfyUIWorkflowPickerItem = (props: IComfyUIWorkflowPickerItemProps) => {
  const { workflow, selected, onSelect } = props;

  return <button 
    className={
      `cursor-pointer ${selected ? 'bg-gray-200' : ''} border border-gray-200 rounded-md`
    } 
    onClick={() => onSelect(workflow)}
  >
    <div className="flex flex-col gap-4">
      <div className="w-full h-48 bg-gray-200 rounded-md">
        <img 
          src={workflow.cover} 
          alt={workflow.name} 
          className="w-full h-full object-cover rounded-md" 
        />
      </div>
      <div className="flex flex-col pb-2">
        <div className="text-m font-bold">{workflow.name}</div>
        <div className="text-xs text-gray-500">
          {workflow?.description?.length && workflow?.description?.length > 100 ? workflow?.description?.slice(0, 100) + '...' : workflow?.description}
        </div>
      </div>
    </div>
  </button>
}

const ComfyUIWorkflowPicker = (props: IComfyUIWorkflowPickerProps) => {
  const { workflow, onSelect } = props;
  const [type, setType] = useState<'aiAppWorkflow' | 'comfyuiWorkflow'>('aiAppWorkflow');
  const [workflows, setWorkflows] = useState<WorkflowItem[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkflowItem | null>(null);
  const [previewId, setPreviewId] = useState<string | undefined | null>(null);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (workflow && workflow.code && workflow.code !== 'new' && !selectedWorkflow) {
      setPreviewId(workflow.code);
      setType('aiAppWorkflow');
    } else if (workflow?.publicId === 'new' && workflow?.contentTpl && !selectedWorkflow) {
      // setPreviewId(workflow.contentTpl);
      // setType('comfyuiWorkflow');
    } else if (workflow === undefined && !selectedWorkflow) {
      setSelectedWorkflow(null);
      setPreviewId(null);
      setType('aiAppWorkflow');
    }
  }, [workflow]);

  useEffect(() => {
    async function fetchWorkflows() {
      const response = await listMaletteWorkflowsByPage({}, pageNo, 10);
      setWorkflows(response.data?.list as WorkflowItem[] || []);
      setHasMore(response.data?.list?.length === 10);
    }
    fetchWorkflows();
  }, []);

  useEffect(() => {
    async function loadMoreWorkflows() {
      const response = await listMaletteWorkflowsByPage({}, pageNo, 10);
      setWorkflows([...workflows, ...(response.data?.list || []) as WorkflowItem[]]);
      setHasMore(response.data?.list?.length === 10);
    }
    if (hasMore) {
      loadMoreWorkflows();
    }
  }, [pageNo]);

  const handleSelect = (workflow: WorkflowItem|null) => {
    setSelectedWorkflow(workflow);
    setPreviewId(workflow?.publicId);
    setType('comfyuiWorkflow');
    onSelect?.(workflow);
  }

  const loadMore = () => {
    if (hasMore) {
      setPageNo(pageNo + 1);
    }
  }

  return <div>
    <div>
      <div className="flex flex-row justify-end">
        <Button 
          type="primary" 
          icon={<ReloadOutlined />} 
          onClick={() => handleSelect(null)} 
          className="mb-4"
          disabled={
            !previewId
          }
        >
          重新选择
        </Button>
      </div>
      {
        previewId ? <AppWorkflowPreview previewId={previewId} type={type} /> : 
        // grid 布局，尽可能铺满宽度
          workflows.length > 0 ? <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
            {
              workflows.map((workflow) => (
                <ComfyUIWorkflowPickerItem 
                  key={workflow.publicId} 
                  workflow={workflow} 
                  selected={selectedWorkflow?.publicId === workflow.publicId}
                  onSelect={handleSelect}
                />
              ))
            }
          </div>
          {
            hasMore ? <div className="flex justify-center mt-4">
                <Button type="link" onClick={loadMore}>加载更多</Button>
              </div> : null
          }
        </div> : <div className="text-center text-gray-500">
          <Empty description="暂无 ComfyUI 工作流">
            <Button type="primary" onClick={() => {
              window.location.href = '/comfyui/new';
            }}>
              去创建
            </Button>
          </Empty>
        </div>
      }
    </div>
  </div>
} 

export default ComfyUIWorkflowPicker;
