import { CommonResponse } from '@/models/common/response';
import request from '@/utils/request';

export async function createLoginQRCode(){
  const response = await request.post(`/api/v1/wechat/create_qrcode`, {});
  return response;
}

export async function checkScanResult(key){
  const response = await request.post(`/api/v1/wechat/checkScanResult`, {
    key
  });
  return response;
}

