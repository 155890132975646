import { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


const CanvasComponent = () => {
  const navigate = useNavigate();
  const { publicId } = useParams();
  const [workflowId, setWorkflowId] = useState<string|undefined>(publicId);
  const initialWorkflowId = useRef<string|undefined>(publicId);

  useEffect(() => {
    if (publicId) {
      setWorkflowId(publicId);
    }
  }, [publicId]);

  useEffect(() => {
    console.log('workflowId: ', workflowId, initialWorkflowId.current);
    if (workflowId && workflowId !== initialWorkflowId.current) {
      navigate(`/comfyui/${workflowId}`, { replace: true });
    }
  }, [workflowId, navigate]);

  // 监听 iframe 的 postMessage
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'workflow-changed') {
        console.log('handleMessage: ', event.data.workflowId);
        setWorkflowId(event.data.workflowId);
      }
    };
  
    window.addEventListener('message', handleMessage);
  
    // 清理函数
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  
  return <div className="w-full h-full">
    <iframe
      title="App Workflow Studio"
      src={`/comfyui/workflow/${initialWorkflowId.current || ''}`} 
      style={{ width: '100%', height: '100%' }}
    />
  </div>
};

export default CanvasComponent;