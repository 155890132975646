import React from 'react';

function GroupQrCode({ qrSrc="https://ablula.oss-accelerate.aliyuncs.com/malette/assets/qr.jpeg!s" }: { qrSrc?: string} ) {
  return (
    <div>
      <img 
        style={{ maxWidth: 256, borderRadius: 8 }} 
        src={qrSrc} />
    </div>
  );
}

export default GroupQrCode;
