import { create } from 'zustand';
import { getUserInfo, logout } from '@/services/user';
import { UserInfo } from '@/models/common/user';
import { ApiResponse, LogoutResponse } from '@/models/common/response';

// 定义获取用户信息和注销操作的接口
interface UserSlice {
  isLoginModalOpen: boolean;
  isSignAgreementModalOpen: boolean;
  isPaymentModalOpen: boolean;
  disableCancel: boolean;
  currentUser?: UserInfo;
  getUserInfo: () => Promise<void>;
  logout: () => Promise<void>;
  showLoginModal: (disableCancel?:boolean) => void;
  hideLoginModal: () => void;
  showSignAgreementModal: (disableCancel?:boolean) => void;
  hideSignAgreementModal: () => void;
  showPaymentModal: () => void;
  hidePaymentModal: () => void;
  locale: string;
  onLocaleChange: (locale: string) => void;
}

// 初始化操作的接口
interface InitSlice {
  initialize: () => Promise<void>;
}

// 合并 UserSlice 和 InitSlice 接口来定义全局 store 的状态
type GlobalStoreState = UserSlice & InitSlice;

// 实现 userSlice 和 initSlice
const me = window?.INIT_STATE?.me;
const localLocale = localStorage.getItem('i18nextLng');

export const createUserSlice = (set): UserSlice => ({
  disableCancel: false,
  isLoginModalOpen: false,
  isSignAgreementModalOpen: false,
  isPaymentModalOpen: false,
  currentUser: me as UserInfo,
  locale: localLocale || 'zh-CN',
  getUserInfo: async () => {
    const userInfoRes: ApiResponse<UserInfo> = await getUserInfo();
    if (userInfoRes?.data?.publicId) {
      set({ currentUser : userInfoRes.data });
    }
  },
  logout: async () => {
    const response: ApiResponse<LogoutResponse> = await logout();
    if (response.success) {
      set({ currentUser : {} });
    }
  },
  showLoginModal: (disableCancel) => {
    set({ isLoginModalOpen: true })
    if (disableCancel) {
      set({ disableCancel: true })
    }
  },
  hideLoginModal: () => {
    set({ isLoginModalOpen: false })
  },
  showSignAgreementModal: (disableCancel) => {
    set({ isSignAgreementModalOpen: true })
    if (disableCancel) {
      set({ disableCancel: true })
    }
  },
  hideSignAgreementModal: () => {
    set({ isSignAgreementModalOpen: false })
  },
  showPaymentModal: () => {
    set({ isPaymentModalOpen: true })
  },
  hidePaymentModal: () => {
    set({ isPaymentModalOpen: false })
  },
  onLocaleChange: (locale) => {
    set({ locale });
  }
});

export const createInitSlice = (set): InitSlice => ({
  initialize: async () => {
    await createUserSlice(set).getUserInfo();
  },
});

export const useGlobalStore = create<GlobalStoreState>((set) => ({
  ...createUserSlice(set),
  ...createInitSlice(set),
}));