import { CommonResponse } from '@/models/common/response';
import { SdModel } from '@/models/sd/SdModel';
import request from '@/utils/request';


export async function createDigitalTwin(name?: string, type: string = 'PET', subType: string = 'CAT'): Promise<CommonResponse<SdModel>> {
  const response = await request.post<SdModel, CommonResponse<SdModel>>('/api/v1/digital-twin/models', {
    name, type, subType
  });
  return response;
}

export async function listDigitalTwins(type : string = 'PET') {
  const response = await request.get<undefined, CommonResponse<SdModel[]>>(`/api/v1/digital-twin/models?type=${type}`);
  return response;
}

export async function getTrainDetail(modelId : string = 'PET') {
  const response = await request.get<undefined, CommonResponse<SdModel[]>>(`/api/v1/digital-twin/train-detail?modelId=${modelId}`);
  return response;
}

export async function getTrainResult(modelId: string, trainRecordId: string) {
  const response = await request.get<undefined, CommonResponse<SdModel[]>>(`/api/v1/digital-twin/train-result?modelId=${modelId}&trainRecordId=${trainRecordId}`);
  return response;
}

export async function startTraining(modelId: string, imageIds: string[]) {
  const response = await request.post<SdModel, CommonResponse<SdModel>>('/api/v1/digital-twin/train', {
    modelId,
    fileIds: imageIds
  });
  return response;
}