import moment from'moment';
import { useCallback, useEffect, useState } from 'react';

import styles from './index.module.scss';
import { createSdFile, listSdFiles } from '@/services/SdFile';
import { SdFile } from '@/models/sd/SdFile';
import { Avatar, Button, Empty, Form, Row, Image as ImageComp, Spin, Tabs, Divider, message } from 'antd';
import { GEN_TYPES } from '@/constants';
import { newPage } from '@/utils';
import { createDigitalTwin, getTrainDetail, listDigitalTwins, startTraining } from '@/services/DigitalTwin';
import { PlusOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import ImageUploader from '@/components/DigitalImageUploader/ImageUploader';

moment.locale('zh-cn');

const me = INIT_STATE.me;

const DEFAULT_COVER_MAP = {
  'DOG': '🐶',
  'CAT': '🐱',
  'GIRL': '👧🏻',
  'BOY': '👦🏻',
}

function DigitalTwinCard({ item, selected, onSelected }, key) {

  const { publicId } = item;

  const handleDigitalTwinCardClick = useCallback(() => {
    // window.open(`/digital-twin/${publicId}`);
    onSelected?.(item);
  }, [item, onSelected]);

  return <div className={styles.DigitalTwinCardWrapper} key={key}>
    <div className={
      selected ? styles.DigitalTwinCardSelected : styles.DigitalTwinCard
    }>
      <div className={styles.imgWrapper} onClick={handleDigitalTwinCardClick}>
        {
          item.cover ? <img src={`https://ablula.oss-accelerate.aliyuncs.com/${item.cover}`} /> : <div className={styles.imgPlaceholder}>
            {
              DEFAULT_COVER_MAP[item.subType || 'CAT']
            }
          </div>
        }
      </div>
      <div className={styles.mask}>

      </div>
    </div>
  </div>
}

function imageFileId2ImageUrl(imageFileId) {
  let _url = imageFileId;
  if (!_url.startsWith('http')) {
    _url = `https://ablula.oss-accelerate.aliyuncs.com/${_url}!medium`;
  }
  if (!_url.endsWith('!medium')) {
    _url = `${_url}!medium`
  }
  return _url;
}

function DigitalTwinList() {
  const [cardList, setCardList] = useState<SdFile[]>([]);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [currentDigitalTwin, setCurrentDigitalTwin] = useState();
  const [trainDetail, setTrainDetail] = useState();
  const [imageFileIds, setImageFileIds] = useState([]);
  const [currentPetType, setCurrentPetType] = useState('DOG');

  const handleStartTraining = useCallback(async () => {
    if (!currentDigitalTwin?.publicId || !imageFileIds?.length) {
      message.error('请上传图片');
      return;
    }
    const imgFileUrls = imageFileIds.map(item => {
      return imageFileId2ImageUrl(item.url);
    });
    const resp = await startTraining(
      currentDigitalTwin.publicId, 
      imgFileUrls
    );
    console.log('resp: ', resp);
  }, [currentDigitalTwin, imageFileIds]);

  const handleSelectDigitalTwin = async (digitalTwin) => {
    setImageFileIds([]);
    setTrainDetail(null);
    setCurrentDigitalTwin(digitalTwin);
    setTimeout(() => {
      handleGetTrainDetail(digitalTwin.publicId, []);
    })
  }

  const handleListDigitalTwins = useCallback(async () => {
    const listFilesResp = await listDigitalTwins();
    setCardList(listFilesResp.data);
    setGlobalLoading(false);
  }, []);

  const handleGetTrainDetail = async (modelId, imageFileIds) => {
    const detailResp = await getTrainDetail(modelId);
    console.log('detail: ', detailResp);
    const originImageList = detailResp?.data?.trainData?.map?.(item => item.imageFileId) || [];
    
    setImageFileIds(originImageList.map(item => ({
      uid: item,
      url: imageFileId2ImageUrl(item),
    })).concat(imageFileIds || []))
    setTrainDetail(detailResp.data);
  }
  
  useEffect(() => {
    handleListDigitalTwins();
  }, [handleListDigitalTwins]);

  const handleCreateDigitalTwin = useCallback(async () => {
    const resp = await createDigitalTwin();
    handleListDigitalTwins();
    // location.href = `/digital-twin/${resp.data.publicId}`;
  }, []);

  if (!currentDigitalTwin && cardList?.length) {
    setCurrentDigitalTwin(cardList[0]);
    handleGetTrainDetail(cardList[0]?.publicId, []);
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Row>
          <div className={
            styles.currentDigitalTwin
          }>
            <div className={styles.imgWrapper}>
              { currentDigitalTwin?.cover
                ? <img src={currentDigitalTwin.cover} /> 
                : <div className={styles.imgPlaceholder}>
                </div> 
              }
            </div>
          </div>
          <div>
            
          </div>
        </Row>
        <Row>
          <div className={styles.cardList}>
            {cardList?.map?.((item, index) => {
              return <DigitalTwinCard selected={
                currentDigitalTwin?.publicId === item.publicId
              } key={`${item.publicId}_${index}`} item={item} onSelected={handleSelectDigitalTwin} />;
            })}
            <div className={styles.DigitalTwinCardWrapper}>
              <div className={styles.DigitalTwinCard}>
                <div className={styles.imgWrapper} onClick={handleCreateDigitalTwin}>
                  <div className={styles.imgPlaceholder}>
                    <PlusOutlined style={{ color: 'white' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
      <div className={styles.content}>
        <Tabs>
          <TabPane tab="原图" key="origin">
          <div className={styles.contentSection}>
            <div className={styles.typeSelection}>
              <div className={styles.petSelection}>
                <div className={currentPetType === 'DOG' ? styles.petItemSelected : styles.petItem} onClick={() => {
                  setCurrentPetType('DOG')
                }}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/sd-online/2023/10/28/gFYI7eEw76.png' />
                </div>
                <div className={currentPetType === 'CAT' ? styles.petItemSelected : styles.petItem} onClick={() => {
                  setCurrentPetType('CAT')
                }}>
                  <img src='https://ablula.oss-accelerate.aliyuncs.com/sd-online/2023/10/17/o27yQHXh3R.png' />
                </div>
              </div>
              <div>
                <Button 
                  onClick={handleStartTraining}
                  style={{ height: 88 }} 
                  type="primary"
                >开始训练</Button>
              </div>
            </div>
            { trainDetail ? <ImageUploader 
                onUploaded={(data) => {
                  setImageFileIds(data?.fileList?.map(item => ({
                    ...item,
                    url: imageFileId2ImageUrl(item.url)
                  })))
                }}
                defaultFileList={imageFileIds}
                multiple
                uploadDescription='上传照片'
                uploadIcon={<PlusOutlined />}
              />
            : 'loading' }
            <Spin style={{ minHeight: 300}} spinning={globalLoading}> 
            
            </Spin>
          </div>
          </TabPane>
          <TabPane tab="写真" key="pics">
            
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}


function ImageCard({ item }) {

  return <ImageComp
    height={'100%'}
    className={styles.imageCard}
    src={`https://ablula.oss-accelerate.aliyuncs.com/${item}`} 
  />
}

export default DigitalTwinList;
