import React, { useRef, useEffect } from 'react';

const getHtmlContent = (data) => {
  let htmlContent = data;
  if (htmlContent && htmlContent.includes('```html')) {
    htmlContent = htmlContent.replace(/```html/, '```');
    const start = htmlContent.indexOf('```');
    const end = htmlContent.lastIndexOf('```');
    htmlContent = htmlContent.substring(start + 3, end);
  }
  return htmlContent;
};

// 使用一个独立的样式环境

const HtmlOutputRender = ({ data, isMobilePortrait }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const shadow = containerRef.current.shadowRoot || containerRef.current.attachShadow({ mode: 'open' });

      // 创建样式元素
      const style = document.createElement('style');
      
      // 创建内容容器
      const container = document.createElement('div');
      container.innerHTML = getHtmlContent(data);

      // 清空 Shadow DOM
      shadow.innerHTML = '';

      // 将样式和内容添加到 Shadow DOM
      shadow.appendChild(style);
      shadow.appendChild(container);

      // 加载 Tailwind CSS
      fetch('https://ablula.oss-accelerate.aliyuncs.com/sd-online/assets/tailwind.js')
        .then(response => response.text())
        .then(scriptContent => {
          // 创建并执行 Tailwind 脚本
          const tailwindScript = document.createElement('script');
          tailwindScript.textContent = scriptContent;
          shadow.appendChild(tailwindScript);

          // 配置 Tailwind
          const tailwindConfig = document.createElement('script');
          tailwindConfig.textContent = `
            tailwind.config = {
              important: true,
              content: [
                '${getHtmlContent(data).replace(/'/g, "\\'")}',
              ],
            }
          `;
          shadow.appendChild(tailwindConfig);

          // 触发 Tailwind 编译
          const observer = new MutationObserver(() => {
            if (shadow.querySelector('[data-tailwind-injected]')) {
              style.textContent = shadow.querySelector('[data-tailwind-injected]').textContent;
              observer.disconnect();
            }
          });
          observer.observe(shadow, { childList: true, subtree: true });

          // 触发 Tailwind 重新编译
          const event = new Event('DOMContentLoaded');
          shadow.dispatchEvent(event);
        })
        .catch(error => console.error('Error loading Tailwind:', error));
    }
  }, [data, isMobilePortrait]);

  return <div ref={containerRef}></div>;
}

export const TextOutputRender = ({ data }) => {
  return (
    <div className='flex p-4 justify-center items-center'>
      {data}
    </div>
  );
}

export default HtmlOutputRender;
