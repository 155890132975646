import { Steps, Button, Divider, FormInstance, message, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeftOutlined, SettingOutlined } from '@ant-design/icons';
import AppCreatorForm from './AppCreatorForm';
import AppBasicInfoForm from './AppBasicInfoForm';
import { WorkflowItem } from '@/constants';
import ComfyUIWorkflowPicker from './ComfyUIWorkflowPicker';
import { app } from '../../comfyui/studio';
import { createWorkflow } from '@/services/Workflow';
import { uuid } from '@/utils';
export interface ICreatorProps {
  open?: boolean;
  workflow?: WorkflowItem;
  onContentTypeChange?: (contentType: 'creator' | 'homepage' | 'guide') => void;
}

const Creator: React.FC<ICreatorProps> = (props: ICreatorProps) => {
  const { open, workflow: aiAppWorkflow, onContentTypeChange } = props;
  const [current, setCurrent] = useState(0);
  const [comfyUIWorkflow, setComfyUIWorkflow] = useState<WorkflowItem | undefined | null>(undefined);
  const [updatedAiAppWorkflow, setUpdatedAiAppWorkflow] = useState<WorkflowItem | undefined>(aiAppWorkflow);
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormInstance<any>>(null);
  const basicInfoFormRef = useRef<FormInstance<any>>(null);

  useEffect(() => {
    setUpdatedAiAppWorkflow(aiAppWorkflow);
    if (aiAppWorkflow?.publicId === 'new') {
      setCurrent(0);
      setComfyUIWorkflow(null);
      setUpdatedAiAppWorkflow(undefined);
    }
  }, [aiAppWorkflow]);

  useEffect(() => {
    async function updateAPIJson() {
      const workflowContent = comfyUIWorkflow?.content;
      if (workflowContent) {
        if (workflowContent.nodes?.length) {
          await app.loadGraphData(workflowContent);
        } else {
          await app.loadJsonData(workflowContent);
        }
        const { output: apiJson } = await app.graphToPrompt();
        setUpdatedAiAppWorkflow({
          ...updatedAiAppWorkflow,
          contentTpl: apiJson
        });
      } else if (updatedAiAppWorkflow?.publicId === 'new') {
        setUpdatedAiAppWorkflow({
          ...updatedAiAppWorkflow,
          contentTpl: undefined
        });
      }
    }
    updateAPIJson();
  }, [comfyUIWorkflow]);

  const workflowPickerStep = {
    title: '选择工作流',
    content: <ComfyUIWorkflowPicker 
      workflow={updatedAiAppWorkflow}
      onSelect={(item) => {
        setComfyUIWorkflow(item);
      }}
    />
  };

  const appParamsConfigStep = {
    title: '参数配置',
    content: <AppCreatorForm
      formRef={formRef}
      workflow={updatedAiAppWorkflow}
      onChange={(workflow) => {
        setUpdatedAiAppWorkflow(workflow);
      }}
    />
  };

  const appBasicInfoStep = {
    title: '完善信息',
    content: <AppBasicInfoForm 
      formRef={basicInfoFormRef}
      workflow={updatedAiAppWorkflow} 
    />
  };

  const items = [
    workflowPickerStep,
    appParamsConfigStep,
    appBasicInfoStep
  ];

  const handleStepChange = (current: number) => {
    if (aiAppWorkflow?.publicId === 'new') {
      return;
    }
    setCurrent(current);
    if (current === 1) {
      formRef.current?.resetFields();
    }
    if (current === 2) {
      basicInfoFormRef.current?.resetFields();
    }
  }

  const handleStepBack = () => {
    setCurrent(current - 1);
  } 

  const handleStepForward = async () => {
    if (current === 0) {
      if (!updatedAiAppWorkflow?.contentTpl) {
        message.error('请先选择工作流');
        return;
      }
    }
    if (current === 1) {
      const validRes = await formRef.current?.validateFields();
      if (!validRes) {
        return;
      }
      const { checkpoints, loras, inviteCodes, ...values } = validRes;
      const contentTpl = updatedAiAppWorkflow?.contentTpl || {};
      checkpoints?.forEach(item => {
        contentTpl[item.nodeId] = item;
      });
      loras?.forEach(item => {
        contentTpl[item.nodeId] = item;
      });
      const outputTpl = values.outputTpl;
      const realWorkflow = {
        ...updatedAiAppWorkflow,
        ...values,
        contentTpl,
        outputTpl,
      };
      if (typeof inviteCodes === 'object') {
        realWorkflow.inviteCodes = JSON.stringify(inviteCodes);
      }
      setUpdatedAiAppWorkflow(realWorkflow);
    }
    setCurrent(current + 1);
  }

  const handleSaveAIApp = async () => {
    const validRes = await basicInfoFormRef.current?.validateFields();
    if (!validRes) {
      return;
    }
    const { inviteCodes, ...values } = validRes;
    const realWorkflow = {
      ...updatedAiAppWorkflow,
      ...values,
    };
    if (typeof inviteCodes === 'object') {
      realWorkflow.inviteCodes = JSON.stringify(inviteCodes);
    }
    setUpdatedAiAppWorkflow(realWorkflow);
    setLoading(true);
    if (realWorkflow.publicId === 'new') {
      delete realWorkflow.publicId;
    }
    const response = await createWorkflow(realWorkflow);
    if (response.success) {
      message.success('创建成功');
      window.location.href = `/workflow/app/${response.data.publicId}`;
    } else {
      message.error('创建失败');
    }
    setLoading(false);
  }

  const handleSaveDraft = async () => {
    try {
      const formValues = await formRef.current?.getFieldsValue();
      const { checkpoints, loras, inviteCodes, ...values } = formValues || {};

      if (!updatedAiAppWorkflow?.contentTpl) {
        message.error('请先选择工作流');
        return;
      }
      const contentTpl = updatedAiAppWorkflow?.contentTpl || {};
      checkpoints?.forEach(item => {
        contentTpl[item.nodeId] = item;
      });
      loras?.forEach(item => {
        contentTpl[item.nodeId] = item;
      });
      const realWorkflow = {
        ...updatedAiAppWorkflow,
        ...values,
        contentTpl,
        isPrivate: 'Y'
      };
      if (values.outputTpl) {
        realWorkflow.outputTpl = {
          'images': {
            nodeId: values.outputTpl,
            paramKey: 'images',
            paramType: 'Array'
          }
        };
      }
      if (typeof inviteCodes === 'object') {
        realWorkflow.inviteCodes = JSON.stringify(inviteCodes);
      }
      setUpdatedAiAppWorkflow(realWorkflow);
      setLoading(true);
      if (realWorkflow.publicId === 'new') {
        delete realWorkflow.publicId;
      }
      if (!realWorkflow.code) {
        realWorkflow.code = uuid().slice(0, 15);
      }
      const response = await createWorkflow(realWorkflow);
      if (response.success) {
        message.success('保存草稿成功');
        window.location.href = `/workflow/app/${response.data.publicId}`;
      } else {
        message.error('保存草稿失败');
      }
      setLoading(false);
    } catch (error) {
      console.error('error: ', error);
      message.error('保存草稿失败，请检查表单内容');
      setLoading(false);
    }
  }

  const handleBack = () => {
    if (updatedAiAppWorkflow?.publicId === 'new') {
      // onContentTypeChange?.('guide');
    } else {
      onContentTypeChange?.('homepage');
    }
  }

  return <div style={{ display: open ? 'block' : 'none' }} className="w-full h-full">
    <div>
      {
        loading && <Spin className='absolute top-0 left-0 w-full h-full' />
      }
    </div>
    <div>
      <div className='flex justify-center items-center w-full pt-4'>
        { aiAppWorkflow?.publicId !== 'new' && <Button ghost style={{ position: 'fixed', left: 300 }} type="primary" onClick={handleBack}>
          <ArrowLeftOutlined />
        </Button> }
        <Steps onChange={(current) => handleStepChange(current)} className='w-1/2' current={current} items={items} />
        {
          aiAppWorkflow?.publicId === 'new' && current !== items.length - 1 && <Button 
            ghost 
            style={{ position: 'fixed', right: 10 }}
            type="primary" 
            onClick={handleSaveDraft}
          >
            保存草稿
          </Button>
        }
      </div>
      <Divider />
      <div className="mt-4">
        <div className="flex flex-col h-[calc(100vh-256px)] overflow-y-auto">
          {items[current].content}
        </div>
        <div className="flex justify-center mt-4 gap-4">
          {
            current > 0 && <Button 
              onClick={handleStepBack}
              disabled={current === 0}
            >
              上一步
            </Button>
          }
          {
            current < items.length - 1 && <Button 
              type="primary" 
              onClick={handleStepForward}
              disabled={
                !updatedAiAppWorkflow?.contentTpl
              }
            >
              下一步
            </Button>
          }
          {
            current === items.length - 1 && <Button 
              disabled={!comfyUIWorkflow && updatedAiAppWorkflow?.publicId === 'new'} 
              type="primary" 
              onClick={() => handleSaveAIApp()}
            >
              {
                updatedAiAppWorkflow?.publicId === 'new' ? '创建' : '保存'
              }
            </Button>
          }
        </div>
      </div>
    </div>
  </div>
}

export default Creator;
