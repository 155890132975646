import { useCallback, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import FileUploader from '../CommonUploader/FileUploader';
import { cn } from '@/lib/utils';
import { Popover, Input, Modal, Tooltip } from 'antd';
import { MoreVertical, Mic, Upload, Link, X, HelpCircle } from 'lucide-react';

interface AudioRecorderProps {
  value?: string;
  onChange?: (value: string) => void;
  maxSize?: number;
}

const AudioRecorder = ({ value, onChange, maxSize = 100 }: AudioRecorderProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const progressRef = useRef<HTMLDivElement>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingTimerRef = useRef<NodeJS.Timeout>();
  const uploaderRef = useRef<any>();
  const [isAudioLoading, setIsAudioLoading] = useState(true);
  const [inputMode, setInputMode] = useState<'record' | 'upload' | 'link'>('record');
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [audioLink, setAudioLink] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleCustomUpload = async (file: File) => {
    setUploadLoading(true);
    try {
      console.log('开始处理上传:', file.name);
      
      const fileList = {
        file: {
          uid: `-${Date.now()}`,
          name: file.name,
          size: file.size,
          type: file.type,
          status: 'uploading',
          percent: 0,
          originFileObj: file
        },
        fileList: [{
          uid: `-${Date.now()}`,
          name: file.name,
          size: file.size,
          type: file.type,
          status: 'uploading',
          percent: 0,
          originFileObj: file
        }]
      };
      
      if (uploaderRef.current?.handleChange) {
        await uploaderRef.current.handleChange(fileList);
      } else {
        console.error('uploaderRef.current.handleChange 不存在');
        messageApi.error('上传组件未就绪');
      }
    } catch (error) {
      console.error('上传处理失败:', error);
      messageApi.error('上传失败');
    } finally {
      setUploadLoading(false);
    }
  };

  const getMimeType = () => {
    const preferredTypes = [
      'audio/mp4; codecs=mp4a.40.2',  // AAC in M4A container
      'audio/mpeg',                    // MP3
      'audio/mp4',                     // 普通 M4A
      'audio/webm; codecs=opus',       // Opus in WebM container
      'audio/webm',                    // 普通 WebM
    ];
    
    for (const type of preferredTypes) {
      if (MediaRecorder.isTypeSupported(type)) {
        console.log('支持的音频格式:', type);
        return type;
      }
    }
    
    console.warn('未找到支持的首选格式，使用默认格式');
    return ''; // 让浏览器使用默认格式
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mimeType = getMimeType();
      
      if (mediaRecorder.current) {
        mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      }
      
      const options: MediaRecorderOptions = {
        audioBitsPerSecond: 128000
      };
      if (mimeType) options.mimeType = mimeType;
      
      const recorder = new MediaRecorder(stream, options);
      mediaRecorder.current = recorder;
      chunksRef.current = [];
      setRecordingTime(0);

      recorder.ondataavailable = (e) => {
        chunksRef.current.push(e.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: recorder.mimeType });
        const extension = recorder.mimeType.includes('mp4') ? '.m4a' : '.webm';
        const file = new File([audioBlob], `recording-${Date.now()}${extension}`, { 
          type: recorder.mimeType 
        });
        await handleCustomUpload(file);
      };

      recorder.start(1000);
      setIsRecording(true);
      
      recordingTimerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } catch (err) {
      console.error('录音失败:', err);
      messageApi.error('录音初始化失败');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
      clearInterval(recordingTimerRef.current);
    }
  };

  const formatTime = useCallback((time: number | string | undefined) => {
    console.log('formatTime 调用:', time, typeof time);
    
    // 转换为数字并验证
    const seconds = Number(time);
    if (!seconds || isNaN(seconds) || seconds === Infinity) {
      return '0:00';
    }
    
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  }, []);

  const handleProgressClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!audioRef.current || !progressRef.current) return;
    
    const rect = progressRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    const newTime = percentage * duration;
    
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  }, [duration]);

  const handleProgressDrag = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !audioRef.current || !progressRef.current) return;
    
    const rect = progressRef.current.getBoundingClientRect();
    const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
    const percentage = x / rect.width;
    const newTime = percentage * duration;
    
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  }, [isDragging, duration]);

  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current;
      
      const handleLoadedMetadata = () => {
        console.log('音频加载完成，时长:', audio.duration);
        setDuration(audio.duration);
        setIsAudioLoading(false);
      };

      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };

      const handleError = (e) => {
        console.error('音频加载错误:', e);
        setIsAudioLoading(false);
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('error', handleError);

      if (audio.readyState >= 1) {
        setDuration(audio.duration);
        setIsAudioLoading(false);
      }

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('error', handleError);
      };
    }
  }, [value]);

  const handleLinkSubmit = () => {
    if (!audioLink) return;
    
    // 验证链接是否为音频文件
    const isAudioUrl = /\.(mp3|wav|m4a|webm)$/i.test(audioLink);
    if (!isAudioUrl) {
      messageApi.error('请输入有效的音频文件链接');
      return;
    }

    onChange?.(audioLink);
    setShowLinkModal(false);
    setAudioLink('');
  };

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setDragActive(false);
    
    const file = e.dataTransfer.files[0];
    if (!file) return;
    
    if (!file.type.startsWith('audio/')) {
      messageApi.error('请上传音频文件');
      return;
    }

    if (file.size > maxSize * 1024 * 1024) {
      messageApi.error(`文件大小不能超过 ${maxSize}MB`);
      return;
    }

    handleCustomUpload(file);
  }, [maxSize]);

  const handleModeChange = async (mode: 'record' | 'upload' | 'link') => {
    setInputMode(mode);
    
    if (mode === 'record') {
      await startRecording();
    } else if (mode === 'upload') {
      uploaderRef.current?.upload?.(); // 触发文件选择
    } else if (mode === 'link') {
      setShowLinkModal(true);
    }
  };

  const handleRecordClick = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      await startRecording();
    }
  };

  const handleUploadClick = (e: React.MouseEvent) => {
    if (uploadLoading) return; // 如果正在上传，阻止新的上传
    
    e.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.wav,.mp3,.m4a,.webm';
    input.style.display = 'none';
    
    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        if (file.size > maxSize * 1024 * 1024) {
          messageApi.error(`文件大小不能超过 ${maxSize}MB`);
          return;
        }
        await handleCustomUpload(file);
      }
    };
    
    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  };

  const renderRecordingInterface = () => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="flex items-center gap-4"
    >
      <button
        onClick={handleRecordClick}
        disabled={loading}
        className={cn(
          "relative flex items-center gap-2 px-6 py-3 rounded-full font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2",
          {
            "bg-red-500 hover:bg-red-600 text-white focus:ring-red-500": isRecording,
            "bg-blue-500 hover:bg-blue-600 text-white focus:ring-blue-500": !isRecording && !loading,
            "bg-gray-200 text-gray-400 cursor-not-allowed": loading
          }
        )}
      >
        {loading ? (
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
          />
        ) : isRecording ? (
          <>
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 1, repeat: Infinity }}
              className="w-2 h-2 bg-red-200 rounded-full"
            />
            {formatTime(recordingTime)}
          </>
        ) : (
          <>
            <Mic size={20} />
            <span>开始录音</span>
          </>
        )}
      </button>
      
      {isRecording && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-sm text-gray-500"
        >
          正在录音...
        </motion.span>
      )}
    </motion.div>
  );

  const renderUploadInterface = () => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="relative"
      onDragOver={(e) => {
        e.preventDefault();
        setDragActive(true);
      }}
      onDragLeave={() => setDragActive(false)}
      onDrop={handleDrop}
    >
      <div className="p-8 border-2 border-dashed border-gray-200 rounded-lg hover:border-blue-500 transition-colors">
        <div className="text-center">
          <div className="w-12 h-12 mx-auto mb-4 bg-blue-50 text-blue-500 rounded-full flex items-center justify-center">
            <Upload size={24} />
          </div>
          <p className="text-sm font-medium text-gray-900">
            点击或拖拽上传音频文件
          </p>
          <p className="mt-1 text-xs text-gray-500">
            支持 MP3、WAV、M4A、WebM 格式，最大 100MB
          </p>
        </div>
        <FileUploader
          ref={uploaderRef}
          value={value}
          onChange={(newValue) => {
            setLoading(false);
            onChange?.(newValue);
          }}
          accept=".wav,.mp3,.m4a,.webm"
          maxSize={maxSize * 1024 * 1024}
        />
      </div>
    </motion.div>
  );

  const renderDragOverlay = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50"
    >
      <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-[2px]" />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="p-8 bg-white rounded-2xl shadow-2xl border-2 border-blue-500 border-dashed">
          <div className="w-16 h-16 mx-auto mb-4 bg-blue-50 text-blue-500 rounded-2xl flex items-center justify-center">
            <Upload size={32} />
          </div>
          <p className="text-lg font-medium text-gray-900">释放鼠标上传音频文件</p>
          <p className="mt-1 text-sm text-gray-500">支持 MP3、WAV、M4A、WebM 格式</p>
        </div>
      </div>
    </motion.div>
  );

  const renderLinkModal = () => (
    <Modal
      title={
        <div className="flex items-center gap-2 px-2">
          <Link size={20} className="text-blue-500" />
          <span>输入音频链接</span>
        </div>
      }
      open={showLinkModal}
      onOk={handleLinkSubmit}
      onCancel={() => {
        setShowLinkModal(false);
        setAudioLink('');
      }}
      okText="确认"
      cancelText="取消"
      width={480}
      className="rounded-2xl"
    >
      <div className="px-2 pt-6">
        <Input
          value={audioLink}
          onChange={(e) => setAudioLink(e.target.value)}
          placeholder="请输入音频文件链接 (.mp3, .wav, .m4a, .webm)"
          prefix={<Link size={16} className="text-gray-400" />}
          className="h-11 text-base"
        />
        <div className="mt-2 text-xs text-gray-500">
          支持的文件格式：MP3、WAV、M4A、WebM，文件大小不超过 100MB
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="w-full">
      <AnimatePresence>
        {dragActive && renderDragOverlay()}
      </AnimatePresence>

      <div className="mb-6">

        {!value && (
          <div className="flex flex-col gap-3">
            {/* 录音选项 */}
            <button
              onClick={handleRecordClick}
              className={cn(
                "w-full flex items-center gap-3 p-4 text-left border rounded-xl transition-all",
                isRecording 
                  ? "border-red-500 bg-red-50"
                  : "border-gray-200 hover:border-blue-500 hover:bg-blue-50"
              )}
            >
              <div className={cn(
                "w-10 h-10 flex items-center justify-center rounded-lg transition-colors",
                isRecording ? "bg-red-100 text-red-600" : "bg-blue-100 text-blue-600"
              )}>
                <Mic size={20} />
              </div>
              <div className="flex-1">
                <div className="font-medium text-gray-900">
                  {isRecording ? "点击停止录音" : "录制音频"}
                </div>
                <div className="text-sm text-gray-500">
                  {isRecording ? formatTime(recordingTime) : "使用麦克风录制音频"}
                </div>
              </div>
              {isRecording && (
                <motion.div
                  animate={{ scale: [1, 1.2, 1] }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="w-3 h-3 bg-red-500 rounded-full"
                />
              )}
            </button>

            {/* 上传选项 - 添加 loading 状态 */}
            <button
              onClick={handleUploadClick}
              disabled={uploadLoading}
              className={cn(
                "w-full flex items-center gap-3 p-4 text-left border rounded-xl transition-all",
                uploadLoading 
                  ? "border-blue-200 bg-blue-50"
                  : "border-gray-200 hover:border-blue-500 hover:bg-blue-50",
                "relative"
              )}
            >
              <div className={cn(
                "w-10 h-10 flex items-center justify-center rounded-lg transition-colors",
                uploadLoading ? "bg-blue-100" : "bg-green-100 text-green-600"
              )}>
                {uploadLoading ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    className="w-5 h-5 border-2 border-blue-500 border-t-transparent rounded-full"
                  />
                ) : (
                  <Upload size={20} />
                )}
              </div>
              <div className="flex-1">
                <div className="font-medium text-gray-900">
                  {uploadLoading ? "正在上传..." : "上传音频"}
                </div>
                <div className="text-sm text-gray-500">
                  {uploadLoading ? (
                    "请稍候，文件正在处理中"
                  ) : (
                    "支持 MP3、WAV、M4A、WebM 格式"
                  )}
                </div>
              </div>
              {uploadLoading && (
                <motion.div
                  initial={{ width: "0%" }}
                  animate={{ width: "100%" }}
                  className="absolute bottom-0 left-0 h-0.5 bg-blue-500"
                  style={{ transformOrigin: "left" }}
                />
              )}
            </button>

            {/* 链接选项 */}
            <button
              onClick={() => setShowLinkModal(true)}
              className="w-full flex items-center gap-3 p-4 text-left border border-gray-200 rounded-xl hover:border-blue-500 hover:bg-blue-50 transition-all"
            >
              <div className="w-10 h-10 flex items-center justify-center bg-purple-100 text-purple-600 rounded-lg">
                <Link size={20} />
              </div>
              <div className="flex-1">
                <div className="font-medium text-gray-900">输入链接</div>
                <div className="text-sm text-gray-500">
                  粘贴音频文件链接
                </div>
              </div>
            </button>
          </div>
        )}

        {/* 音频播放器 */}
        {value && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="relative p-4 bg-gray-50 rounded-xl flex items-center gap-3"
          >
            <button
              onClick={() => {
                if (!audioRef.current) return;
                if (isPlaying) {
                  audioRef.current.pause();
                } else {
                  audioRef.current.play();
                }
                setIsPlaying(!isPlaying);
              }}
              className="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full bg-blue-500 hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {isPlaying ? (
                <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                  <rect x="6" y="4" width="4" height="16" rx="1" />
                  <rect x="14" y="4" width="4" height="16" rx="1" />
                </svg>
              ) : (
                <svg className="w-4 h-4 text-white ml-0.5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z" />
                </svg>
              )}
            </button>

            <div className="flex-1 flex items-center gap-3">
              <span className="text-xs text-gray-500 w-8">
                {formatTime(currentTime)}
              </span>

              <div className="flex-1">
                <div 
                  ref={progressRef}
                  className="relative h-1 bg-gray-200 rounded-full cursor-pointer group"
                  onClick={handleProgressClick}
                  onMouseDown={() => setIsDragging(true)}
                  onMouseUp={() => setIsDragging(false)}
                  onMouseLeave={() => setIsDragging(false)}
                  onMouseMove={handleProgressDrag}
                >
                  <motion.div 
                    className="absolute inset-y-0 left-0 bg-blue-500 rounded-full"
                    style={{ width: `${duration ? (currentTime / duration) * 100 : 0}%` }}
                  />
                  <div 
                    className="absolute top-1/2 -mt-2 -ml-2 w-4 h-4 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                    style={{ left: `${duration ? (currentTime / duration) * 100 : 0}%` }}
                  />
                </div>
              </div>

              <span className="text-xs text-gray-500 w-8">
                {duration ? formatTime(duration) : '0:00'}
              </span>
            </div>

            <button
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.pause();
                  setIsPlaying(false);
                }
                onChange?.('');
              }}
              className="flex-shrink-0 w-8 h-8 flex items-center justify-center text-gray-400 hover:text-red-500 hover:bg-gray-100 rounded-full transition-colors"
            >
              <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>

            <audio
              ref={audioRef}
              src={value}
              onLoadedMetadata={(e) => {
                const audio = e.target as HTMLAudioElement;
                console.log('Audio loaded, raw duration:', audio.duration, typeof audio.duration);
                
                // 只在有效的数字时间时更新状态
                if (audio.duration && 
                    !isNaN(audio.duration) && 
                    audio.duration !== Infinity && 
                    typeof audio.duration === 'number') {
                  setDuration(audio.duration);
                } else {
                  console.warn('Invalid audio duration:', audio.duration);
                  setDuration(0);
                }
              }}
              onTimeUpdate={(e) => {
                const audio = e.target as HTMLAudioElement;
                const time = audio.currentTime;
                if (!isNaN(time) && time !== Infinity) {
                  setCurrentTime(time);
                }
              }}
              onEnded={() => setIsPlaying(false)}
              preload="metadata"
              className="hidden"
            />
          </motion.div>
        )}
      </div>

      {/* 文件上传组件 */}
      <FileUploader
        ref={uploaderRef}
        value={value}
        onChange={(newValue) => {
          setLoading(false);
          onChange?.(newValue);
        }}
        accept=".wav,.mp3,.m4a,.webm"
        maxSize={maxSize * 1024 * 1024}
        className="hidden"
      />

      {/* 链接输入模态框 */}
      <Modal
        title={
          <div className="flex items-center gap-2">
            <Link size={20} className="text-purple-500" />
            <span>输入音频链接</span>
          </div>
        }
        open={showLinkModal}
        onOk={handleLinkSubmit}
        onCancel={() => {
          setShowLinkModal(false);
          setAudioLink('');
        }}
        okText="确认"
        cancelText="取消"
        width={480}
        className="rounded-2xl"
      >
        <div className="pt-6">
          <Input
            value={audioLink}
            onChange={(e) => setAudioLink(e.target.value)}
            placeholder="请输入音频文件链接 (.mp3, .wav, .m4a, .webm)"
            prefix={<Link size={16} className="text-gray-400" />}
            className="h-11"
          />
          <div className="mt-2 text-xs text-gray-500">
            支持的文件格式：MP3、WAV、M4A、WebM，文件大小不超过 100MB
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AudioRecorder; 