

import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';

const WorkflowManagement: React.FC = () => {
  return <div className={styles.main}>
    <Outlet />
  </div>
};

export default WorkflowManagement;
