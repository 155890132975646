import { useGlobalStore } from '@/store';

import styles from './index.module.scss';
import { Input, Button } from "antd";
import { useState } from 'react';
import { generateAPIToken } from '@/services/user';

const Developer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const user = useGlobalStore((state) => state.currentUser);
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);
  const handleGenerate = async () => {
    setLoading(true);
    console.log('generateAPIToken');
    await generateAPIToken();
    getUserInfo();
    setLoading(false);
  }

  return <div className={styles.accountSettings}>
    <div className={styles.profile}>
    <span className={styles.title}>Developer</span>
    <div className={styles.content}>
      <div className={styles.group}>
        <div className={styles.item}>
          <span>API TOKEN</span>
          <div className={styles.subItem}>
            <Input value={user?.apiToken} />
            <Button loading={loading} type="primary" onClick={handleGenerate}>Generate</Button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
};

export default Developer;