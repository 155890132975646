import { CommonResponse } from '@/models/common/response';
import { SdFile, SdTask, SdTaskResult } from '@/models/sd/SdFile';
import request from '@/utils/request';


export async function listResampleTaskResults(): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/task-results?type=RESAMPLE`);
  return response;
}

export async function listResampleTasks(): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/tasks?type=RESAMPLE`);
  return response;
}

export async function listUnfinishedResampleTasks(): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/tasks?type=RESAMPLE&unfinished=true`);
  return response;
}

export async function createResampleTask(param): Promise<CommonResponse<SdTask>> {
  const response = await request.post<SdTask, CommonResponse<SdTask>>(`/api/v1/tools/resample`, {
    ...param
  });
  return response;
}
