import { CommonResponse } from '@/models/common/response';
import { SdFile, SdTask, SdTaskResult } from '@/models/sd/SdFile';
import request from '@/utils/request';

const baeUrl = '';

export async function listSdFiles(): Promise<CommonResponse<SdFile[]>> {
  const response = await request.get<undefined, CommonResponse<SdFile[]>>(baeUrl + '/api/v1/sd/files');
  return response;
}

export async function getSdFile(publicId: string): Promise<CommonResponse<SdFile>> {
  return await request.get(`${baeUrl}/api/v1/sd/files/${publicId}`);
}

export async function getSdTask(publicId: string): Promise<CommonResponse<SdTask>> {
  return await request.get(`${baeUrl}/api/v1/sd/tasks/${publicId}`);
}

export async function createSdFile(param: SdFile): Promise<CommonResponse<SdFile>> {
  const response = await request.post<SdFile, CommonResponse<SdFile>>(baeUrl + '/api/v1/sd/files', {
    sdFile: {...param}
  });
  return response;
}

export async function updateSdFile(publicId: string, param: SdFile): Promise<CommonResponse<SdFile>> {
  const response = await request.patch<SdFile, CommonResponse<SdFile>>(`/api/v1/sd/files/${publicId}`, {
    sdFile: {...param}
  });
  return response;
}

export async function createSdTask(param: SdTask): Promise<CommonResponse<SdTask>> {
  const response = await request.post<SdTask, CommonResponse<SdTask>>(`/api/v1/sd/tasks`, {
    sdTask: {...param}
  });
  return response;
}

export async function listSdTasksBySdFileId(sdFileId): Promise<CommonResponse<SdTask[]>> {
  const response = await request.get<undefined, CommonResponse<SdTask[]>>(`/api/v1/sd/tasks/sdfileId/${sdFileId}`);
  return response;
}

export async function listUnfinishedSdTasksBySdFileId(sdFileId): Promise<CommonResponse<SdTask[]>> {
  const response = await request.get<undefined, CommonResponse<SdTask[]>>(`/api/v1/sd/tasks/unfinished/sdfileId/${sdFileId}`);
  return response;
}

export async function listSdTasksResultsBySdFileId(sdFileId): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/task-result/sdfileId/${sdFileId}`);
  return response;
}

export async function checkBalance(batchSize): Promise<CommonResponse<string>> {
  const response = await request.get<string, CommonResponse<string>>(`/api/v1/sd/tasks/check-balance?batchSize=${batchSize}`);
  return response;
}

export async function listTaskResultsByTaskIds(taskIds): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.post<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/task-result/taskIds`, {
    taskIds
  });
  return response;
}
