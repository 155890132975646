import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface WorkflowPreviewProps {
  className?: string;
}

export function WorkflowPreview({ className = '' }: WorkflowPreviewProps) {
  const controls = useAnimation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // sm breakpoint
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const animateNodes = async () => {
      await controls.start({
        opacity: 1,
        transition: { duration: 0.5 }
      });
    };

    animateNodes();
  }, [controls]);

  // 更新连接线路径计算
  const getConnectionPaths = () => {
    if (isMobile) {
      return [
        "M 80 90 L 80 140", // 垂直连接线，从第一个节点到第二个节点
        "M 80 200 L 80 250" // 垂直连接线，从第二个节点到第三个节点
      ];
    }
    return [
      "M 120 50 L 240 50", // 水平连接线，从第一个节点到第二个节点
      "M 360 50 L 480 50"  // 水平连接线，从第二个节点到第三个节点
    ];
  };

  return (
    <div ref={containerRef} className={`relative w-full max-w-full ${className}`}>
      {/* Workflow Preview Container */}
      <div className="relative bg-[#1E1E1E] rounded-xl overflow-hidden w-full">
        {/* Workflow Header */}
        <div className="flex items-center justify-between px-3 sm:px-6 py-3 bg-[#2D2D2D] border-b border-gray-800">
          <div className="text-white font-medium text-sm sm:text-base">Text to Image Workflow</div>
          <div className="flex items-center space-x-2">
            <div className="px-2 sm:px-3 py-1 rounded-full bg-purple-500/20 text-purple-400 text-xs sm:text-sm">
              Active
            </div>
          </div>
        </div>

        {/* Workflow Content */}
        <div className="p-3 sm:p-6">
          {/* Nodes */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={controls}
            className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4 relative min-h-[300px] sm:min-h-0"
          >
            {/* Input Node */}
            <div className="p-3 sm:p-4 rounded-lg bg-purple-500/10 border border-purple-500/20">
              <div className="text-purple-400 font-medium mb-1 sm:mb-2 text-sm sm:text-base">Input</div>
              <div className="text-xs sm:text-sm text-gray-400">Text Prompt</div>
            </div>

            {/* Process Node */}
            <div className="p-3 sm:p-4 rounded-lg bg-blue-500/10 border border-blue-500/20">
              <div className="text-blue-400 font-medium mb-1 sm:mb-2 text-sm sm:text-base">Process</div>
              <div className="text-xs sm:text-sm text-gray-400">Stable Diffusion</div>
            </div>

            {/* Output Node */}
            <div className="p-3 sm:p-4 rounded-lg bg-green-500/10 border border-green-500/20">
              <div className="text-green-400 font-medium mb-1 sm:mb-2 text-sm sm:text-base">Output</div>
              <div className="text-xs sm:text-sm text-gray-400">Generated Image</div>
            </div>

            {/* Connections */}
            <svg
              className="absolute inset-0 w-full h-full pointer-events-none"
              style={{ zIndex: -1 }}
              preserveAspectRatio="none"
            >
              {getConnectionPaths().map((path, index) => (
                <motion.path
                  key={index}
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 1, delay: 0.5 + index * 0.2 }}
                  d={path}
                  stroke="rgba(168, 85, 247, 0.4)"
                  strokeWidth="2"
                  fill="none"
                />
              ))}
            </svg>
          </motion.div>
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute -top-4 -left-4 w-16 sm:w-24 h-16 sm:h-24 bg-purple-500/20 rounded-full blur-2xl" />
      <div className="absolute -bottom-4 -right-4 w-20 sm:w-32 h-20 sm:h-32 bg-pink-500/20 rounded-full blur-2xl" />
    </div>
  );
}