import { isChineseDomain } from "@/utils";

export default {
	screenMobile: 991,
	screenNormal: 992,
	screenData: 3840,
	i18nDef: isChineseDomain() ? 'zh-CN' : 'en-US',
	i18nEnum: {
		ZHCN: {
			name: "中文简体",
			value: "zh-CN",
		},
		ENUS: {
			name: "English",
			value: "en-US",
		},
	},
	i18nEnumKey: {
		"zh-CN": "中文简体",
		"en-US": "English",
	},
	i18nKey: /\/zh-CN|en-US|zh-TW\//,
	i18nKeyArr: ["zh-CN", "en-US", "zh-TW"],
	i18nKeys: [
		{
			label: "中文简体",
			value: "zh-CN",
			key: "zh-CN",
		},
		{
			label: "English",
			value: "en-US",
			key: "en-US",
		},
	],
	sizeKeys: [
		{
			label: "默认",
			value: "default",
		},
		{
			label: "大型",
			value: "large",
		},
		{
			label: "小型",
			value: "small",
		},
	],
  theme: {
    components: {
      Layout: {
        headerBg: 'white',
        bodyBg: 'white',
      }
    },
    // algorithm: [theme.defaultAlgorithm, theme.compactAlgorithm],
    token: {
      // // Seed Token，影响范围大
      colorPrimary: '#8a5df6',
      // borderRadius: 2,

      // // 派生变量，影响范围小
      colorBgContainer: 'white',
    },
  }
};