import { Steps } from "antd";
import { useState } from "react";

export interface IWorkflowAppGuildProps {
  open: boolean;
}


const steps = [
  {
    title: '选择工作流',
    content: <div>
      <div>
        <p className="text-lg font-bold">从 ComfyUI 工作流列表中选出一个工作流。</p>
      </div>
      <div className="border-2 border-gray-300 rounded-md p-4">
        <img src="https://ablula.oss-accelerate.aliyuncs.com/sd-online/assets/app-maker-guide/step1.png" />
      </div>
    </div>
  },
  {
    title: '参数配置',
    content: <div>
      <div>
        <p className="text-lg font-bold">配置工作流的参数。</p>
      </div>
      <div className="border-2 border-gray-300 rounded-md p-4">
        <img src="https://ablula.oss-accelerate.aliyuncs.com/sd-online/assets/app-maker-guide/step2.png" />
      </div>
    </div>
  },
  {
    title: '完善信息',
    content: <div>
      <div>
        <p className="text-lg font-bold">完善工作流的名称、描述、图标等信息。</p>
      </div>
      <div className="border-2 border-gray-300 rounded-md p-4">
        <img src="https://ablula.oss-accelerate.aliyuncs.com/sd-online/assets/app-maker-guide/step3.png" />
      </div>
    </div>
  }
]

const WorkflowAppGuide: React.FC<IWorkflowAppGuildProps> = ({ open }) => {
  const [current, setCurrent] = useState(0);

  const onChange = (current: number) => {
    setCurrent(current);
  };

  return <div style={{ display: open ? 'block' : 'none' }}>
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold">
        工作流应用创建指南
      </h1>
    </div>
    <div className="flex flex-col items-center justify-center">
      <Steps
        type="navigation"
        size="default"
        current={current}
        onChange={onChange}
        className="site-navigation-steps"
        items={steps.map((step, index) => ({
          title: step.title,
          subTitle: step.subTitle,
          description: step.description,
        }))}
      />
    </div>
    <div className="flex flex-col items-center justify-center mt-4">
      <div>

      </div>
      <div>
        {steps[current].content}
      </div>
    </div>
  </div>
}

export default WorkflowAppGuide;