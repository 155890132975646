import { CommonResponse } from '@/models/common/response';
import { SdFile, SdTask, SdTaskResult } from '@/models/sd/SdFile';
import request from '@/utils/request';


export async function listRembgTaskResults(): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/task-results?type=REMBG`);
  return response;
}

export async function listRembgTasks(): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/tasks?type=REMBG`);
  return response;
}

export async function listUnfinishedRembgTasks(): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request.get<string, CommonResponse<SdTaskResult[]>>(`/api/v1/sd/tasks?type=REMBG&unfinished=true`);
  return response;
}

export async function createRembgTask(param): Promise<CommonResponse<SdTask>> {
  const response = await request.post<SdTask, CommonResponse<SdTask>>(`/api/v1/tools/rembg`, {
    ...param
  });
  return response;
}
