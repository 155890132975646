import { useCallback, useEffect, useState } from 'react';

import { Button, Form, Image, Input, InputNumber, Space, Spin, message } from 'antd';
import { SdTask, SdTaskResult } from '@/models/sd/SdFile';

import InputSlider from '@/components/InputSlider';
import { Loading } from '@/components/Loading';

import styles from './index.module.scss'
import { CloseOutlined, DownloadOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import ImageUploader from '@/components/Uploader/ImageUploader';
import { getProfileData } from '@/services/user';
import { Profile } from '@/models/common/user';
import { getSdTask, listTaskResultsByTaskIds } from '@/services/SdFile';
import { sleep } from '@/utils';
import { createRembgTask, listRembgTaskResults, listUnfinishedRembgTasks } from '@/services/Rembg';

const onDownload = async (filename, link) => {
  let img = new window.Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  img.onload = function(){
    let canvas = document.createElement('canvas')
    let context = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    context.drawImage(img, 0, 0, img.width, img.height)
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = filename || 'default.png'
      a.href = url
      a.dispatchEvent(event)
      URL.revokeObjectURL(url)  // 内存管理,将这句代码注释掉,则将以 blob:http 开头的url复制到浏览器地址栏有效,否则无效.
    })    
  }
  img.src = link + '?v=' + Date.now()
};


interface FieldData {
  name: string | number | (string | number)[];
  value?: unknown;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const me = INIT_STATE.me;

function Rembg() {
  const [form] = Form.useForm();
  const [sdTaskResults, setSdTaskResults] = useState<SdTaskResult[]>([]);
  const [generating, setGenerating] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [unfinishedTasks, setUnfinishedTasks] = useState<SdTask[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [previewIndex, setPreviewIndex] = useState(0);
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [profileData, setProfileData] = useState<Profile>({});
  const [ imagePath, setImagePath ] = useState(null);

  const handleGenerate = async () => {
    try {
      setGenerating(true);
      const updates = form.getFieldsValue();
      const param = {
        imagePath,
        width: updates.width || 512,
        height: updates.height  || 512,
        imageNum: updates.batch_size || 1,
      };

      if (!imagePath) {
        throw new Error("请上传图片");
      }

      let initLoadingCount = 0;
      initLoadingCount += (updates?.batch_size || 1);
      if (initLoadingCount > 0) {
        for (let i = 0; i < initLoadingCount; i++) {
          sdTaskResults.unshift({
            loading: true,
          })
        }
      }

      const taskResp = await createRembgTask(param);
      const profileResp = await getProfileData();
      setProfileData(profileResp.data);
      setUnfinishedTasks([taskResp.data]);
    } catch (e) {
      console.error('handle gen failed: ', e);
      messageApi.error(e.message);
    }
    setGenerating(false);
  }

  useEffect(() => {
    const init = async () => {
      const profileResp = await getProfileData();
      const taskResultsResp = await listRembgTaskResults();
      const unfinishedTasksResp = await listUnfinishedRembgTasks();

      if (profileResp?.data) {
        setProfileData(profileResp.data);
      }

      const imageDataList: SdTaskResult[] = [];

      if (taskResultsResp?.data?.length) {
        imageDataList.push(...(taskResultsResp.data));
      }
      if (unfinishedTasksResp.data) {
        let initLoadingCount = 0;
        unfinishedTasksResp.data.forEach((item: SdTask) => {
          initLoadingCount += (item.params?.batch_size || 1);
        });
        if (initLoadingCount > 0) {
          for (let i = 0; i < initLoadingCount; i++) {
            imageDataList.unshift({
              loading: true,
            })
          }
          setUnfinishedTasks(unfinishedTasksResp.data);
        }
      }
      setSdTaskResults(imageDataList);
      setGlobalLoading(false);
    }
    init();
  }, []);

  useEffect(() => {
    if (!unfinishedTasks?.length) return;
    const loopFetchResult = async () => {
      let hasResult = false;
      while (!hasResult) {
        unfinishedTasks.forEach(async item => {
          const taskResp = await getSdTask(item.publicId as string);
          if (taskResp?.data?.stage === 'FINISHED') {
            hasResult = true;
          }
        });
        if (!hasResult) {
          await sleep(2000);
        }
      }
      const taskIds = unfinishedTasks.map(item => item.publicId);
      const taskResultsResp = await listTaskResultsByTaskIds(taskIds);

      if (taskResultsResp?.data?.length) {
        const newSdResult = sdTaskResults?.filter?.(item => !item.loading);
        newSdResult.unshift(...(taskResultsResp.data));
        setUnfinishedTasks([]);
        setSdTaskResults(newSdResult);
        setTimeout(() => {
          setGenerating(false);
          setPreviewIndex(0);
        });
      }
    }
    loopFetchResult();
  }, [sdTaskResults, unfinishedTasks]);

  const handleImageUploaderChange = useCallback((file) => {

    let width = file?.width;
    let height = file?.height;
    if (file?.width > 2048 || file?.height > 2048) {
      messageApi.warning("图片长边大于 2048，将按照长边等比缩放到 2048");

      if (file.width > file.height) {
        const ratio = file.height/file.width;
        width = 2048;
        height = width * ratio;
      } else {
        const ratio = file.width/file.height;
        height = 2048;
        width = height * ratio;
      }
    }
    const parsedFieldDataList: FieldData[] = [];
    parsedFieldDataList.push({
      name: 'width',
      value: width
    });
    parsedFieldDataList.push({
      name: 'height',
      value: height
    });
    setFormFields(parsedFieldDataList);
    setImagePath(file.url)
  }, [messageApi]);

  useEffect(() => {
    const currentTaskResult = sdTaskResults?.[previewIndex];
    if (!currentTaskResult) return;
    setFormFields([]);
    const params = currentTaskResult?.params;
    const parsedFieldDataList: FieldData[] = [];
    parsedFieldDataList.push({
      name: 'width',
      value: params?.width
    });
    parsedFieldDataList.push({
      name: 'height',
      value: params?.height
    });
    setFormFields(parsedFieldDataList);
    if (params?.init_image_path) {
      setImagePath(params.init_image_path)
    }
  }, [previewIndex, sdTaskResults]);
  
  return (
    <Spin spinning={globalLoading}><div className={styles.container}>
      {contextHolder}
      <div className={styles.content}>
        <Form className={styles.formWrapper} layout="vertical" form={form} fields={formFields} onFinish={handleGenerate} >
          <div className={styles.contentSection}>
            <div className={styles.bottomParamArea}>
              <div className={styles.leftArea}>
                <div className={styles.topParamArea}>
                  <div className={styles.prompts}>
                  <div className={styles.imageUploader}>
                    {
                      imagePath
                        ? <div className={styles.imgWrapper}>
                            <Image height={375} style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}`} /> 
                            <Button className={styles.closeBtn} onClick={handleImageUploaderChange.bind(null, { url: undefined})} type='text'><CloseOutlined /></Button>
                          </div>
                        : <ImageUploader onUploaded={handleImageUploaderChange.bind(null)} />
                    }
                  </div>
                  </div>
                  <div className={styles.samplerArea}>
                    <Form.Item name="width" className={styles.item} label="宽度">
                       <InputSlider disabled min={128} max={2048} precision={2} />
                    </Form.Item>
                    <Form.Item name="height" className={styles.item} label="高度">
                      <InputSlider disabled min={128} max={2048} precision={2} /> 
                    </Form.Item>
                  </div>
                  <div className={styles.actions}>
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      className={styles.generateBtn} 
                      loading={generating}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        { generating ? '抠图中' : '开始抠图' }
                        <span style={{ fontSize: 8 }}> 剩余 { profileData?.mcoinAccount?.balance?.replace?.('.00', '') } M币 </span>
                      </div>
                    </Button>
                  </div>
                </div>
                <div className={styles.miniImageGallery}>
                  <div className={styles.bigImage}>
                    {sdTaskResults?.length ? <Image.PreviewGroup
                      preview={{
                        current: previewIndex,
                        onChange: setPreviewIndex,
                        toolbarRender: (
                          _,
                          {
                            transform: { scale },
                            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                          },
                        ) => (
                          <Space size={12} className="toolbar-wrapper">
                            <DownloadOutlined onClick={() => {
                              onDownload(sdTaskResults[previewIndex]?.publicId, `https://ablula.oss-accelerate.aliyuncs.com/${sdTaskResults[previewIndex]?.results}`);
                            }} />
                            <SwapOutlined rotate={90} onClick={onFlipY} />
                            <SwapOutlined onClick={onFlipX} />
                            <RotateLeftOutlined onClick={onRotateLeft} />
                            <RotateRightOutlined onClick={onRotateRight} />
                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                          </Space>
                        ),
                      }}
                      items={sdTaskResults?.map?.(item => `https://ablula.oss-accelerate.aliyuncs.com/${item.results}`)}
                    > { 
                        sdTaskResults[previewIndex] 
                          ? !sdTaskResults[previewIndex].loading 
                            ? (sdTaskResults[previewIndex]?.results === '图片违规' ? <span>图片违规</span> : <Image
                              height="100%"
                              style={{ maxHeight: '100%' }}
                              className={styles.bigImageChild} 
                              src={`https://ablula.oss-accelerate.aliyuncs.com/${sdTaskResults[previewIndex]?.results}`} /> )
                            : <Loading visibility="visible" />
                          : null 
                      }
                    </Image.PreviewGroup> : null}
                  </div>
                  <div className={styles.imageListContainer}>
                    { sdTaskResults?.length ? sdTaskResults.map((item, index) => {
                        if (item.loading) {
                          return <div className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
                            <Spin />
                          </div>
                        }
                        return (
                          <div className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
                            { item.results === '图片违规' ? <span>{item.results}</span> : <img src={`https://ablula.oss-accelerate.aliyuncs.com/${item.results}`} /> }
                          </div>
                        )
                      })
                    : null }
                  </div>
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.rightArea}>
                <div className={styles.bigImage}>
                  {sdTaskResults?.length ? <Image.PreviewGroup
                    preview={{
                      current: previewIndex,
                      onChange: setPreviewIndex,
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                        },
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <DownloadOutlined onClick={() => {
                            onDownload(sdTaskResults[previewIndex]?.publicId, `https://ablula.oss-accelerate.aliyuncs.com/${sdTaskResults[previewIndex]?.results}`);
                          }} />
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                        </Space>
                      ),
                    }}
                    items={sdTaskResults?.map?.(item => `https://ablula.oss-accelerate.aliyuncs.com/${item.results}`)}
                  > { 
                      sdTaskResults[previewIndex] 
                        ? !sdTaskResults[previewIndex].loading 
                          ? (sdTaskResults[previewIndex]?.results === '图片违规' ? <span>图片违规</span> : 
                            <Image 
                              height="100%"
                              style={{ maxHeight: '100%' }}
                              className={styles.bigImageChild} 
                              src={`https://ablula.oss-accelerate.aliyuncs.com/${sdTaskResults[previewIndex]?.results}`} 
                            /> )
                          : <Loading visibility="visible" />
                        : null 
                      }</Image.PreviewGroup> : null}
                </div>
                <div className={styles.imageListContainer}>
                  { sdTaskResults?.length ? sdTaskResults.map((item, index) => {
                      if (item.loading) {
                        return <div className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
                          <Spin />
                        </div>
                      }
                      return (
                        <div className={ previewIndex === index ? styles.selectedPreviewItem : styles.previewItem} onClick={() => { setPreviewIndex(index) }}>
                          { item.results === '图片违规' ? <span>{item.results}</span> : <img src={`https://ablula.oss-accelerate.aliyuncs.com/${item.results}`} /> }
                        </div>
                      )
                    })
                  : null }
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
    </Spin>
  );
}

export default Rembg;
