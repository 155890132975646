import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import cls from "classnames/bind";
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { isChineseDomain } from '@/utils';
import { signAgreement } from '@/services/user';
import { useGlobalStore } from '@/store';
const cx = cls.bind(styles);
const isChineseDomainFlag = isChineseDomain();

function SignAgreementDialog(props) {
  const { open, onCancel, onOk, ...dialogProps } = props;
  const getUserInfo = useGlobalStore((state) => state.getUserInfo);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const onAgree = async () => {
    setLoading(true);
    await signAgreement();
    await getUserInfo();
    onCancel();
    setLoading(false);
    setTimeout(() => {
      onOk();
    }, 500);
  }

  return (
    <Modal
      className={cx('dialog')}
      open={open} 
      {...dialogProps}
      onCancel={(e) => {
        onCancel?.(e)
        window.currentLoopScanKey = '';
      }}
      footer={false}
      afterClose={() => {
        window.currentLoopScanKey = '';
      }}
      centered
      style={{ backgroundColor: 'transparent' }}
    >
      <div className={cx('contentWrapper')}>
        <div className={cx('title')}>{t('signAgreementDialog.title')}</div>
        <div className={cx('content')}>
          <Trans i18nKey="signAgreementDialog.content" components={{ 
              term: <a target="_blank" href={`/static/${isChineseDomainFlag ? '' : 'en/'}agreement`}></a>,
              privacy: <a target="_blank" href={`/static/${isChineseDomainFlag ? '' : 'en/'}privacy`}></a>
            }} 
          />
        </div>
        <div className={cx('footer')}>
          <Button type="default" onClick={onCancel}>{t('signAgreementDialog.cancel')}</Button>
          <Button loading={loading} type="primary" onClick={onAgree}>{t('signAgreementDialog.agree')}</Button>
        </div>
      </div>
    </Modal>
  );
}

export default SignAgreementDialog;
