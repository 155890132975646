import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import cls from "classnames/bind";
import styles from './index.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { isChineseDomain } from '@/utils';
const cx = cls.bind(styles);
const isChineseDomainFlag = isChineseDomain();

function PaymentModal(props) {
  const { open, onCancel, onOk: propsOnOK, ...dialogProps } = props;
  const [t] = useTranslation();

  const onOk = () => {
    location.href = '/space/member';
    propsOnOK?.();
  }

  return (
    <Modal
      className={cx('dialog')}
      open={open} 
      {...dialogProps}
      onCancel={(e) => {
        onCancel?.(e)
      }}
      footer={false}
      centered
      style={{ backgroundColor: 'transparent' }}
    >
      <div className={cx('contentWrapper')}>
        <div className={cx('title')}>{t('PaymentModal.title')}</div>
        <div className={cx('content')}>
          <Trans i18nKey="PaymentModal.content" components={{ 
              term: <a target="_blank" href={`/static/${isChineseDomainFlag ? '' : 'en/'}agreement`}></a>,
              privacy: <a target="_blank" href={`/static/${isChineseDomainFlag ? '' : 'en/'}privacy`}></a>
            }} 
          />
        </div>
        <div className={cx('footer')}>
          <Button type="default" onClick={onCancel}>{t('PaymentModal.cancel')}</Button>
          <Button type="primary" onClick={onOk}>{t('PaymentModal.agree')}</Button>
        </div>
      </div>
    </Modal>
  );
}

export default PaymentModal;
