
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Empty, Spin, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Creator from './creator';
import { WorkflowItem } from "@/constants";
import { listWorkflows, listWorkflowsByPage } from '@/services/Workflow';

import LeftPanel from '@/components/LeftPanel';
import { PlusOutlined } from '@ant-design/icons';
import WorkflowList from '@/components/WorkflowList';
import WorkflowGuide from './config/guide';
import AIAppHomepage from './app/Homepage';

export interface IWorkflowItemProps {
  item: WorkflowItem;
  onEdit?: (item?: WorkflowItem) => void;
  onlyPreview?: boolean;
}

const WorkflowHome: React.FC = () => {
  const { publicId } = useParams();
  const [ t ] = useTranslation();
  const navigate = useNavigate(); // 跳转
  const [ contentType, setContentType ] = useState<'guide' | 'creator' | 'homepage'>('guide');
  const [ updateFlag, setUpdateFlag ] = useState<number>(1);
  const [ workflowList, setWorkflowList ] = useState<WorkflowItem[]>([]);
  const [ currentWorkflow, setCurrentWorkflow ] = useState<WorkflowItem>();
  const [ workflowListLoading, setWorkflowListLoading ] = useState(false);
  const [ pageNo, setPageNo ] = useState(1);
  const [ hasMore, setHasMore ] = useState(true);
  
  useEffect(() => {
    const fetchWorkflowList = async () => {
      try {
        setWorkflowListLoading(true);
        const response = await listWorkflowsByPage({ isDefault: false}, 1, 10);
        const workflowList = response.data?.list?.map(item => {
          return {
            ...item,
            contentTpl: JSON.parse(item?.contentTpl || '{}'),
            paramTpl: JSON.parse(item?.paramTpl || '{}'),
            outputTpl: JSON.parse(item?.outputTpl || '{}'),
          };
        });
        setWorkflowList(workflowList || []);
        setHasMore(workflowList?.length === 10);
      } catch (error) {
        notification.error({ message: '获取工作流列表失败', description: error.message });
      } finally {
        setWorkflowListLoading(false);
      }
    };

    fetchWorkflowList();
  }, [updateFlag]);

  useEffect(() => {
    if (!publicId) {
      setContentType('guide');
      return;
    }
    const newWorkflowCount = workflowList.filter(item => item.publicId === 'new').length; 
    if (currentWorkflow?.publicId === publicId && newWorkflowCount !== 0) {
      return;
    }
    if (publicId === 'new') {
      const newWorkflow: WorkflowItem = {
        publicId: 'new',
        name: `新建工作流${newWorkflowCount + 1}`,
        description: '新建工作流',
        cover: 'https://ablula.oss-accelerate.aliyuncs.com/malette/assets/logo.png!xs',
        contentTpl: {},
        paramTpl: {},
        outputTpl: {},
      };
      setCurrentWorkflow(newWorkflow);
      if (newWorkflowCount === 0) {
        setWorkflowList([newWorkflow, ...workflowList]);
      }
      setContentType('creator');
      return;
    }
    const workflow = workflowList.find(item => item.publicId === publicId);
    if (workflow) {
      setCurrentWorkflow(workflow);
      if (workflow.publicId !== 'new') {
        setContentType('homepage');
      }
    }
  }, [publicId, workflowList]);
  
  useEffect(() => {
    const loadMoreWorkflowList = async () => {
      const response = await listWorkflowsByPage({ isDefault: false}, pageNo, 10);
      const newWorkflowList = response.data?.list?.map(item => {
        return {
          ...item,
          contentTpl: JSON.parse(item?.contentTpl || '{}'),
          paramTpl: JSON.parse(item?.paramTpl || '{}'),
          outputTpl: JSON.parse(item?.outputTpl || '{}'),
        };
      });
      setWorkflowList([...workflowList, ...newWorkflowList]);
      setHasMore(newWorkflowList?.length === 10);
    }
    loadMoreWorkflowList();
  }, [pageNo]);

  const createNewWorkflowApp = () => {
    setCurrentWorkflow(undefined);
    navigate('/workflow/app/new');
  }

  const handleSelectWorkflow = (workflow: WorkflowItem) => {
    navigate(`/workflow/app/${workflow.publicId}`);
  }

  const loadMore = () => {
    setPageNo(pageNo + 1);
  }

  return <div className="h-[calc(100vh-66px)] mt-px w-full flex flex-row z-10 relative bg-white">
    <div>
      <LeftPanel fixed={true}>
        <div className="pb-4 px-1">
          {/* 操作区，垂直布局 */}
          <div className="pt-4 flex flex-col gap-1 justify-between items-center mb-4 px-1 bg-white">
            <span className="flex w-full">
              <Button
                disabled={publicId === 'new'}
                style={{ width: '100%' }}
                type="primary"
                onClick={createNewWorkflowApp}
              >
                <PlusOutlined /> 新建 AI 应用
              </Button>
            </span>
          </div>
          <Divider />
          <Spin spinning={workflowListLoading}>
            <div style={{ height: 'calc(100vh - 200px)', 'overflowY': 'scroll' }}>
              {
                workflowList?.length 
                ? <div>
                  <WorkflowList 
                    selectedItemId={currentWorkflow?.publicId} 
                    items={workflowList} 
                    onSelected={handleSelectWorkflow} 
                  />
                  {
                    hasMore && <div className="flex justify-center items-center">
                      <Button type="link" onClick={loadMore}> {t('common.more')} </Button>
                    </div>
                  }
                </div>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无 AI 应用"/>
              }
            </div>
          </Spin>
        </div>
      </LeftPanel>
    </div>
    <div className="flex-auto p-4 h-[calc(100vh-66px)] overflow-y-auto">
      { contentType === 'guide' &&   <div className="flex flex-col">
          <WorkflowGuide open={contentType === 'guide'} />
      </div> }
      { contentType === 'homepage' && currentWorkflow && <div className="flex flex-col">
        <AIAppHomepage 
          onContentTypeChange={setContentType}
          open={contentType === 'homepage'} 
          workflow={currentWorkflow}
          />
        </div>
      }
      { contentType === 'creator' && currentWorkflow && <div className="flex flex-col">
        <Creator
          onContentTypeChange={setContentType}
          open={contentType === 'creator'}
          workflow={currentWorkflow}
          />
        </div>
      }
    </div>
  </div>
};

export default WorkflowHome;