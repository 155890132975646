import { useEffect, useState } from "react";
import WorkflowItem from "../WorkflowItem";

export interface IWorkflowListProps {
  items: any[];
  onSelected?: (item: any) => void;
  selectedItemId?: string;
}

const WorkflowList = (props: IWorkflowListProps) => {
  const { items, onSelected, selectedItemId } = props;
  const [ currentItem, setCurrentItem ] = useState<any>(null);

  const onItemSelected = (item: any) => {
    setCurrentItem(item);
    onSelected && onSelected(item);
  }

  useEffect(() => {
    if (selectedItemId) {
      const item = items.find(item => item.publicId === selectedItemId);
      setCurrentItem(item);
    } else {
      setCurrentItem(null);
    }
  }, [selectedItemId, items]);

  return (
    <div>
      {
        items.map(item => (
          <WorkflowItem key={item.publicId} item={item} onSelected={onItemSelected} selected={
            currentItem?.publicId === item.publicId
          } />
        ))
      }
    </div>
  );
}

export default WorkflowList;